.section-footer {
  a {
    color: #3d8be8;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: #89beec;
      text-decoration: underline;
    }
  }
  .footer-logo-wrap {
    display: flex;
    justify-content: center;
    margin-bottom: 67px;
    @media (width <= 767px) {
      margin-bottom: 20px;
    }
  }
  .section-title {
    font-size: 30px;
    line-height: 45px;
  }
  .section-subtitle {
    font-size: 20px;
    line-height: 30px;
    @media (width <= 767px) {
      max-width: 280px;
      margin: auto;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .subsection-footer-topmenu {
    padding: 90px 0;
    @media (width <= 767px) {
      padding: 20px 0;
    }
    .footer-topmenu {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 64px;
      @media (width <= 767px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 0;
      }
    }
    .topmenu-col {
      @media (width <= 767px) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 32px;
        padding: 32px 0;
        border-bottom: rgba(17, 17, 17, 0.15) 1px solid;
        &:last-child {
          border: none;
        }
        &.show {
          .topmenu-col-menu {
            display: block;
          }
          .topmenu-col-title {
            &::after {
              transform: rotate(45deg);
            }
          }
        }
      }
      &-icon {
        @media (width <= 767px) {
          padding-left: 24px;
        }
      }
      &-title {
        font-size: 18px;
        line-height: 32px;
        font-weight: 600;
        text-transform: uppercase;
        margin-top: 14px;
        margin-bottom: 6px;
        @media (width <= 767px) {
          flex: 1;
          display: flex;
          justify-content: space-between;
          padding-right: 24px;
          &::after {
            font-size: 18px;
            font-weight: 500;
            content: '+';
            cursor: pointer;
            transition: all 0.3s ease-in-out;
          }
        }
      }
      &-menu {
        display: flex;
        flex-direction: column;
        gap: 6px;
        padding: 0;
        margin: 0;
        @media (width <= 767px) {
          width: 100%;
          padding: 50px 32px 0;
          display: none;
        }
        &-item {
          font-size: 16px;
          line-height: 32px;
          list-style: none;
          a {
          }
        }
      }
    }
  }
  .subsection-footer-mainmenu {
    background: #fff7e4;
    margin-top: 20px;
    .footer-mainmenu {
      padding: 24px 0;
      display: flex;
      gap: 24px;
      justify-content: space-between;
      @media (width <= 767px) {
        flex-direction: column;
        gap: 0;
      }
    }
    .footer-mainmenu-col {
      margin-top: 14px;
      @media (width <= 767px) {
        padding-bottom: 14px;
        border-bottom: rgba(17, 17, 17, 0.15) 1px solid;
        &:last-child {
          border: none;
        }
        &.show {
          .footer-mainmenu-list {
            display: block;
          }
          .col-heading {
            &::after {
              transform: rotate(45deg);
            }
          }
        }
      }
    }
    .col-heading {
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 6px;
      font-weight: 600;
      @media (width <= 767px) {
        flex: 1;
        display: flex;
        justify-content: space-between;
        padding: 10px 24px 5px;
        font-weight: 500;
        cursor: pointer;
        &::after {
          font-size: 18px;
          content: '+';
          cursor: pointer;
          transition: all 0.3s ease-in-out;
        }
      }
    }
    .footer-mainmenu-list {
      display: flex;
      flex-direction: column;
      gap: 6px;
      padding: 0;
      margin: 0;
      list-style: none;
      @media (width <= 767px) {
        padding: 22px 32px 0;
        display: none;
      }
    }
    .footer-mainmenu-item {
      font-size: 16px;
      line-height: 24px;
    }
  }
  .subsection-footer-bottom {
    .container {
      padding: 48px 0;
      display: flex;
      gap: 60px;
      align-items: center;
      @media (width <= 767px) {
        flex-direction: column;
        padding-top: 0;
        gap: 0;
      }
    }
    .footer-copyright {
      @media (width <= 767px) {
        width: 300px;
        margin: auto;
        padding-top: 48px;
        margin-bottom: 16px;
      }
    }
    .footer-links {
      @media (width <= 767px) {
        width: 300px;
        margin: auto;
      }
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        gap: 16px;
        @media (width <= 767px) {
          flex-wrap: wrap;
          column-gap: 0;
        }
        li {
          max-width: 100%;
          width: 140px;
          @media (width <= 767px) {
            width: 50%;
          }
        }
      }
    }
    .footer-social-links {
      display: flex;
      align-items: center;
      gap: 30px;
      margin-left: auto;
      @media (width <= 767px) {
        order: -1;
        background: #fff7e4;
        justify-content: center;
        padding: 24px 0 40px;
        margin: 0;
        width: 100%;
      }
    }
  }
}
