.page-online-foundation-program {
  .section-title {
    font-size: 36px;
    line-height: 43px;
    font-family: var(--font-lora);
    @media (width <= 768px) {
      font-size: 28px;
      line-height: 34px;
    }
  }
  .section-offer-bar {
    background: rgba(111, 114, 131, 1);
    .container {
      height: 67px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 40px;
      @media (width <= 768px) {
        gap: 10px;
      }
    }
    .offer-text {
      font-size: 20px;
      color: #fff;
      @media (width <= 768px) {
        font-size: 16px;
        width: 160px;
        line-height: normal;
      }
      span {
        color: rgb(255, 209, 111);
      }
    }
    a.offer-btn {
      background: linear-gradient(#ffd16f, #ff865b);
      display: block;
      width: 150px;
      height: 42px;
      color: #fff;
      text-align: center;
      font-size: 18px;
      line-height: 40px;
      text-decoration: none;
      border-radius: 5px;
      @media (width <= 768px) {
        width: 128px;
        height: 37px;
        line-height: 35px;
      }
    }
  }
  .section-banner {
    background: url('/images/online-foundation-banner-bg.jpg') no-repeat bottom
      right;
    height: 698px;
    background-size: cover;
    @media (width <= 768px) {
      padding-bottom: 354px;
      background-position: bottom right;
      background-size: auto 354px;
      height: auto;
    }
    .banner-online-foundation {
      padding-top: 50px;
      @media (width <= 768px) {
        padding-top: 20px;
      }
    }
    .banner-title {
      font-size: 48px;
      line-height: 52px;
      font-weight: 400;
      padding-top: 50px;
      max-width: 500px;
      @media (width <= 768px) {
        font-size: 28px;
        line-height: 34px;
        padding-top: 20px;
      }
    }
    .banner-text {
      font-size: 28px;
      line-height: 32px;
      font-family: var(--font-lora);
      font-style: italic;
      padding-top: 20px;
      max-width: 400px;
      @media (width <= 768px) {
        padding-top: 0px;
        font-size: 24px;
        line-height: 28px;
      }
    }
    .banner-button {
      padding-top: 40px;
      display: flex;
      flex-direction: column;
      max-width: 313px;
      gap: 20px;
      @media (width <= 768px) {
        padding-top: 20px;
        gap: 10px;
        padding-bottom: 40px;
      }
      a.button {
        font-size: 16px;
        line-height: 19px;
        font-weight: 500;
        height: 48px;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #31364e;
        border-radius: 10px;
        background: white;
        &.btn-primary {
          background: linear-gradient(#ffd16f, #ff865b);
          color: #fff;
          font-size: 18px;
          line-height: 22px;
          text-align: center;
        }
        &.btn-secondary {
          border: #31364e 2px solid;
        }
      }
    }
  }
  section.discover-yoga {
    padding: 50px 0;
    .container {
      display: flex;
      @media (width <= 768px) {
        flex-direction: column;
      }
    }
    .section-title {
      margin-bottom: 30px;
    }
    .description {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 20px;
    }
    p {
      font-size: 16px;
      color: rgb(119, 119, 119);
    }
    .box-right {
      width: 700px;
      flex-shrink: 0;
      @media (width <= 991px) {
        width: 350px;
      }
      @media (width <= 768px) {
        width: 100%;
      }
    }
  }
  section.experience {
    padding-top: 50px;
    padding-bottom: 100px;
    background: linear-gradient(#fff, #f8fbfd);
    border-bottom: #cdcdcd 1px solid;
    @media (width <= 991px) {
      padding-top: 0;
      padding-bottom: 50px;
    }
    .section-title {
      text-align: center;
      margin-bottom: 30px;
      @media (width <= 768px) {
        margin-bottom: 15px;
      }
    }
    .experience-boxes {
      display: flex;
      gap: 30px;
      flex-wrap: wrap;
      @media (width <= 768px) {
        flex-direction: column;
        gap: 15px;
      }
      .box-item {
        background: rgba(213, 207, 207, 0.34);
        border-radius: 18px;
        flex: 1;
        padding: 20px;
        @media (width <= 991px) {
          flex-basis: calc(50% - 15px);
        }
        .box-title {
          font-size: 20px;
          color: rgb(49, 54, 78);
          margin-bottom: 10px;
          font-weight: 500;
        }
        p {
          color: rgb(119, 119, 119);
          font-size: 16px;
        }
      }
    }
  }
  section.fits-schedule {
    background: linear-gradient(#fff, #fff7ed);
    .container {
      background: url(/images/fits-into-schedule.webp) no-repeat bottom left;
      background-size: auto 495px;
      display: flex;
      padding-top: 80px;
      gap: 20px;
      padding-bottom: 80px;
      @media (width <= 768px) {
        padding-top: 382px;
        background-position: top left;
        background-size: auto 382px;
        flex-direction: column;
        padding-bottom: 30px;
      }
    }
    .box-left {
      display: flex;
      gap: 50px;
      @media (width <= 768px) {
        flex-direction: column;
        gap: 20px;
        padding-top: 20px;
        width: 290px;
        margin: auto;
      }
      .section-title {
        width: 225px;
      }
      .section-desc {
        width: 290px;
        font-size: 20px;
        line-height: 24px;
      }
    }
    .box-right {
      background: white;
      border: #aaaaaa 1px solid;
      border-radius: 16px;
      width: 344px;
      padding: 20px;
      @media (width <= 768px) {
        width: 290px;
        margin: auto;
      }
      .schedule-title {
        font-size: 26px;
        line-height: 29px;
        font-weight: 500;
        margin-bottom: 20px;
        a {
          color: rgb(49, 54, 78);
          text-decoration: none;
        }
      }
      .schedule-info {
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
        margin-bottom: 20px;
      }
      .schedule-included {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 20px;
      }
      .schedule-action {
        margin-bottom: 20px;
        .button {
          background: linear-gradient(#ffd16f, #ff865b);
          width: 242px;
          height: 48px;
          border-radius: 10px;
          color: #fff;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          line-height: 46px;
          display: block;
          text-decoration: none;
        }
      }
      .schedule-offer {
        font-size: 20px;
        line-height: 24px;
        @media (width <= 768px) {
          text-align: center;
        }
      }
    }
  }
  section.testimony {
    background: rgba(111, 114, 131, 1);
    padding: 80px 0;
    color: white;
    text-align: center;
    @media (width <= 768px) {
      padding: 40px 0;
    }
    .container {
      max-width: 870px;
    }
    .stars-wrap {
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
    }
    .testimony-text {
      font-size: 26px;
      line-height: 29px;
      font-weight: 500;
      margin-bottom: 20px;
      @media (width <= 768px) {
        font-size: 20px;
        line-height: 24px;
        font-weight: 400;
        margin-bottom: 10px;
      }
    }
    .testimony-author {
      font-size: 20px;
      line-height: 24px;
      @media (width <= 768px) {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  section.meditation-home {
    padding: 80px 0;
    @media (width <= 768px) {
      padding: 40px 0;
    }
    .container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      @media (width <= 768px) {
        flex-direction: column;
      }
    }
    .section-title {
      margin-bottom: 20px;
      @media (width <= 768px) {
        min-height: 124px;
        display: flex;
        align-items: center;
        background: url('/images/meditation-at-home.webp') no-repeat center
          right;
        background-size: auto 124px;
        padding-right: 144px;
      }
    }
    .description {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 20px;
    }
    .box-left {
      max-width: 444px;
      p {
        color: rgb(119, 119, 119);
        font-size: 16px;
        line-height: 24px;
      }
    }
    .box-right {
      flex-shrink: 0;
      @media (width <= 991px) {
        flex-shrink: unset;
        width: 300px;
      }
      @media (width <= 768px) {
        display: none;
      }
    }
  }
  section.sri-yoga {
    padding: 50px 0;
    background: linear-gradient(#e6e6e6, #fff);
    .section-title {
      text-align: center;
      margin-bottom: 40px;
      @media (width <= 768px) {
        margin-bottom: 20px;
      }
    }
    .sri-yoga-boxes {
      display: flex;
      gap: 30px;
      flex-wrap: wrap;
      max-width: 900px;
      margin: auto;
      @media (width <= 768px) {
        flex-direction: column;
        gap: 15px;
      }
      .box-item {
        border: #e9e9e9 1px solid;
        background: white;
        border-radius: 18px;
        padding: 20px;
        flex: 1;
        .box-title {
          font-size: 20px;
          color: rgb(49, 54, 78);
          margin-bottom: 10px;
          font-weight: 500;
        }
        .box-content {
          color: rgb(119, 119, 119);
          font-size: 16px;
        }
      }
    }
    .section-action {
      display: flex;
      justify-content: center;
      padding-top: 40px;
      a.button {
        font-size: 16px;
        line-height: 19px;
        font-weight: 500;
        width: 242px;
        height: 48px;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background: white;
        background: linear-gradient(#ffd16f, #ff865b);
        color: #fff;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
      }
    }
  }
  section.what-expect {
    padding: 50px 0;
    .upper-container {
      display: flex;
      gap: 20px;
      align-items: center;
      @media (width <= 768px) {
        flex-direction: column;
        align-items: flex-start;
        background: url('/images/asana1.jpg') no-repeat 150px 0;
        background-size: auto 128px;
      }
    }
    .meet-box {
      background: rgba(245, 132, 94, 1);
      width: 204px;
      height: 204px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      flex-shrink: 0;
      font-size: 26px;
      line-height: 29px;
      padding: 20px;
      text-align: center;
      @media (width <= 768px) {
        width: 132px;
        height: 132px;
        font-size: 20px;
        line-height: 24px;
      }
    }
    .section-title {
      margin-bottom: 20px;
    }
    .description {
      font-size: 20px;
      max-width: 480px;
    }
    .lower-container {
      display: flex;
      gap: 20px;
    }
    .expect-boxes {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      padding-top: 20px;
      @media (width <= 768px) {
        flex-direction: column;
      }
      .box-item {
        background: rgba(213, 207, 207, 0.34);
        border-radius: 18px;
        width: calc(50% - 15px);
        padding: 20px;
        @media (width <= 768px) {
          width: 100%;
        }
        .box-title {
          font-size: 20px;
          color: rgb(49, 54, 78);
          margin-bottom: 10px;
          font-weight: 500;
        }
        p {
          color: rgb(119, 119, 119);
          font-size: 16px;
        }
      }
    }
    .yoga-image-wrap {
      width: 540px;
      flex-shrink: 0;
      @media (width <= 991px) {
        width: 250px;
      }
      @media (width <= 768px) {
        display: none;
      }
    }
  }
  section.upcoming-dates {
    background-image: url(/images/web-panel-gradient-lighter.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 50px 0;
    text-align: center;
    position: relative;
    &::before {
      background: rgba(255, 255, 255, 0.57);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      content: '';
      display: block;
      z-index: 0;
    }
    .container {
      position: relative;
      z-index: 1;
    }
    .section-title {
      margin-bottom: 20px;
    }
    .description {
      font-size: 20px;
      color: rgb(49, 54, 78);
      line-height: 24px;
      margin-bottom: 30px;
    }
    .date-info {
      color: rgb(119, 119, 119);
      font-size: 16px;
    }
    .section-action {
      padding-top: 30px;
      a.button {
        background: linear-gradient(#ffd16f, #ff865b);
        width: 333px;
        height: 48px;
        line-height: 48px;
        color: #fff;
        text-decoration: none;
        text-align: center;
        font-weight: 500;
        display: inline-flex;
        border-radius: 10px;
        justify-content: center;
      }
    }
  }
  section.testimonials {
    padding: 80px 0 150px;
    @media (width <= 768px) {
      padding: 40px 0 80px;
    }
    .container {
      max-width: 900px;
    }
    .description {
      font-size: 20px;
      line-height: 22px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 10px;
    }
    .section-title {
      text-align: center;
      margin-bottom: 50px;
      @media (width <= 768px) {
        margin-bottom: 20px;
      }
    }
    .testimonial-boxes {
      display: flex;
      gap: 30px;
      @media (width <= 768px) {
        flex-direction: column;
        gap: 100px;
      }
      .box-item {
        background: rgba(247, 245, 244, 1);
        border-radius: 18px;
        padding: 30px 20px 50px;
        text-align: center;
        position: relative;
        @media (width <= 768px) {
          padding-bottom: 80px;
        }
      }
      .quote-icon {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
      }
      .review-text {
        margin-bottom: 40px;
        @media (width <= 768px) {
          margin-bottom: 20px;
        }
      }
      .author-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        transform: translateX(-50%);
        position: absolute;
        left: 50%;
      }
      .author-name {
        font-size: 16px;
        color: rgb(125, 125, 125);
      }
      .author-location {
        font-size: 14px;
        color: rgb(119, 119, 119);
      }
    }
  }
  section.contact {
    padding: 50px 0;
    text-align: center;
    .section-title {
      margin-bottom: 20px;
    }
  }
  section.ae-logos {
    background: rgba(137, 190, 236, 1);
    padding: 40px 0;
    margin-bottom: 50px;
    @media (width <= 768px) {
      padding: 20px 0;
    }
    .container {
      display: flex;
      justify-content: center;
    }
    .logosAe {
      @media (width <= 768px) {
        height: 55px;
        width: auto;
      }
    }
  }
}
