.primary-btn {
  padding: 0 24px;
  background: linear-gradient(to left, #ff9200, #ffb800);
  border: none;
  height: 62px;
  width: 300px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  letter-spacing: 1.4px;
  svg {
    width: 14px;
    height: 14px;
  }
  &.bg-white {
    background: $white;
  }
  &.bg-transparent {
    background: none;
  }
  &.border-black {
    border: #000 1px solid;
  }
  &.color-white {
    color: white;
  }
}
