.page-premium-course {
  .section-banner {
    background-color: rgb(255, 252, 243);
    .banner-title {
      font-family: var(--font-work-sans);
      font-size: 48px;
      line-height: 45px;
      font-weight: 600;
      max-width: 476px;
      grid-area: btitle;
      @media screen and (max-width: 991px) {
        font-size: 34px;
        line-height: 34px;
        text-wrap: balance;
        margin-bottom: 0;
        max-width: 350px;
      }
    }
    .banner-text {
      font-size: 22px;
      grid-area: btext;
      @media screen and (max-width: 991px) {
        max-width: 300px;
        line-height: normal;
      }
    }
    .banner-premium-course {
      min-height: 400px;
      padding: 60px 33px 0;
      display: grid;
      align-items: center;
      grid-template-areas:
        'btitle bimage'
        'btext bimage'
        'bbutton bimage';
      grid-auto-rows: min-content;
      justify-content: space-between;
      @media screen and (max-width: 768px) {
        grid-template-areas:
          'btitle'
          'btext'
          'bimage'
          'bbutton';
        grid-auto-rows: min-content;
        gap: 10px;
        max-width: 300px;
        margin: auto;
        padding: 30px 0 30px;
      }
    }
    .banner-button {
      grid-area: bbutton;
      margin-top: 50px;
      @media screen and (max-width: 768px) {
        margin-top: 20px;
      }
      .button {
        background-color: rgba(223, 121, 0, 1);
        border-radius: 10px;
        color: white;
        font-size: 18px;
        line-height: 22px;
        font-weight: 700;
        width: 300px;
        height: 62px;
        padding: 0 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        @media screen and (max-width: 991px) {
          font-size: 16px;
        }
        @media screen and (max-width: 479px) {
          width: 275px;
        }
      }
    }
    .banner-image {
      grid-area: bimage;
      margin: 0 50px 0 0;
      width: 300px;
      @media screen and (max-width: 768px) {
        margin: 40px auto 0;
      }
      @media screen and (max-width: 479px) {
        width: 275px;
      }
    }
  }
  .premium-key-points {
    background: url('/images/discover-bg.png') repeat-x bottom left;
    padding-bottom: 20px;
    @media screen and (max-width: 768px) {
      background-color: rgb(255, 252, 243);
    }
    .key-points-listing {
      display: flex;
      padding: 20px 0px;
      justify-content: space-between;
      gap: 70px;
      @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 50px;
        max-width: 300px;
        margin: auto;
      }
    }
    .key-points-item {
      display: flex;
      gap: 50px;
      flex: 1;
      @media screen and (max-width: 768px) {
        gap: 20px;
      }
    }
    .key-points-item-image {
      flex-shrink: 0;
    }
    .key-points-item-content {
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      text-wrap: balance;
    }
    .discover-box {
      padding-top: 50px;
      .section-content {
        @media screen and (max-width: 768px) {
          flex-direction: column;
          display: flex;
        }
      }
      .sky-sahaj-image {
        float: right;
        margin-left: 90px;
        @media screen and (max-width: 768px) {
          float: none;
          margin: auto;
          order: 1;
          max-width: 234px;
        }
      }
      .section-title {
        font-size: 25px;
        font-weight: 600;
        max-width: 282px;
        line-height: normal;
        margin-bottom: 50px;
      }
      p {
        font-size: 18px;
        @media screen and (max-width: 768px) {
          font-size: 16px;
        }
        + p {
          margin-top: 30px;
          @media screen and (max-width: 768px) {
            order: 2;
            margin-top: 15px;
          }
        }
      }
    }
  }
  .section-course-highlights {
    .container {
      background: url('/images/calendar-online-mobile.png') no-repeat right top;
      background-size: 457px 325px;
      @media screen and (max-width: 768px) {
        background-size: 200px auto;
      }
    }
    .section-title {
      line-height: normal;
      padding: 80px 0;
      font-size: 36px;
      text-align: center;
      @media screen and (max-width: 768px) {
        padding: 30px 0 50px;
        font-size: 28px;
      }
      strong {
        font-weight: 600;
      }
    }
    .course-highlights-listing {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      @media screen and (max-width: 768px) {
        max-width: 320px;
        margin: auto;
        gap: 15px;
      }
    }
    .course-highlights-item {
      flex: calc(50% - 10px);
      position: relative;
      @media screen and (max-width: 768px) {
        flex: 100%;
      }
    }
    .course-highlights-item-content {
      position: absolute;
      bottom: 0;
      padding: 40px 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 30px;
      width: 100%;
      @media screen and (max-width: 991px) {
        gap: 15px;
      }
      @media screen and (max-width: 768px) {
        align-items: flex-start;
        gap: 10px;
        padding: 30px 15px;
      }
    }
    .course-highlights-item-title {
      font-family: var(--font-lora);
      font-size: 36px;
      font-style: italic;
      line-height: normal;
      text-align: center;
      text-wrap: balance;
      @media screen and (max-width: 991px) {
        font-size: 28px;
      }
      @media screen and (max-width: 768px) {
        text-align: left;
      }
    }
    .course-highlights-item-text {
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      @media screen and (max-width: 991px) {
        font-size: 16px;
      }
      @media screen and (max-width: 768px) {
        text-align: left;
        line-height: 20px;
      }
    }
  }
  .section-impacts {
    padding-bottom: 100px;
    @media screen and (max-width: 768px) {
      padding-bottom: 50px;
    }
    .section-title {
      padding-top: 100px;
      font-size: 36px;
      line-height: normal;
      text-align: center;
      margin-bottom: 20px;
      @media screen and (max-width: 991px) {
        padding-top: 50px;
        font-size: 28px;
        margin-bottom: 5px;
        text-align: left;
        text-wrap: balance;
      }
      strong {
        font-weight: 600;
      }
    }
    .section-description {
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      @media screen and (max-width: 991px) {
        text-align: left;
      }
    }
    .impacts-listing {
      padding-top: 80px;
      display: flex;
      gap: 20px;
      @media screen and (max-width: 991px) {
        padding-top: 20px;
        flex-direction: column;
        gap: 10px;
      }
    }
    .impacts-item {
      flex: 1;
      display: flex;
      gap: 20px;
      background: rgba(231, 249, 248, 1);
      padding: 20px 30px;
      @media screen and (max-width: 768px) {
        padding: 15px 20px;
        gap: 10px;
      }
      &:nth-child(2) {
        background: rgba(244, 251, 255, 1);
      }
      &:nth-child(3) {
        background: rgba(244, 241, 251, 1);
      }
    }
    .impacts-item-image {
      flex-shrink: 0;
      padding-top: 5px;
    }
    .impacts-item-title {
      font-size: 30px;
      font-weight: 600;
      line-height: 30px;
      margin-bottom: 20px;
      @media screen and (max-width: 768px) {
        font-size: 24px;
        margin-bottom: 10px;
        text-wrap: balance;
      }
    }
    .impacts-item-text {
      font-size: 16px;
      line-height: normal;
    }
  }
  .section-courses-nearme {
    .container {
      max-width: 1334px;
    }
    padding-bottom: 100px;
    @media screen and (max-width: 768px) {
      padding-bottom: 50px;
    }
  }
  .section-testimonial {
    background: url('/images/premium-testimonial-bg.png') repeat-x top center;
    padding-top: 80px;
    margin-bottom: 40px;
    .section-title {
      font-size: 42px;
      font-weight: 600;
      line-height: normal;
      padding: 0px 0 50px 0;
      text-align: center;
      @media screen and (max-width: 768px) {
        font-size: 36px;
        text-align: left;
      }
    }
    .slick-dots {
      bottom: 100px;
      @media screen and (max-width: 768px) {
        bottom: 60px;
      }
      li {
        margin: 0 4px;
        width: 10px;
        height: 10px;
      }
      button {
        padding: 0px;
        width: 10px;
        height: 10px;
        &::before {
          font-size: 12px;
          width: 10px;
          height: 10px;
        }
      }
    }
    .slick-slider {
      padding-bottom: 140px;
      @media screen and (max-width: 768px) {
        padding-bottom: 100px;
      }
    }
    .slick-slide {
      padding: 0 10px;
    }
    .slide-wrap {
      background: white;
      border-bottom: 9px solid #faa826;
      min-height: 390px;
      padding: 25px 20px;
      display: flex !important;
      flex-direction: column;
    }
    .slide-title {
      font-size: 24px;
      font-weight: 600;
      line-height: 30px;
      background: url('/images/quote-original.png') no-repeat right top;
      background-size: 54px 35px;
      padding-right: 80px;
      font-size: 24px;
      line-height: 32px;
      font-weight: 400;
      font-family: var(--font-lora);
      margin-bottom: 20px;
      @media screen and (max-width: 768px) {
        font-size: 19px;
        line-height: 26px;
      }
    }
    .slide-text {
      font-family: var(--font-lora);
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      font-style: italic;
      padding-right: 20px;
    }
    .slide-author-info {
      display: flex;
      gap: 20px;
      padding-top: 20px;
      margin-top: auto;
      .author-image {
        flex-shrink: 0;
        img {
          width: 71px;
          height: 71px;
          border-radius: 100%;
          @media screen and (max-width: 768px) {
            width: 62px;
            height: 62px;
          }
        }
      }
      .author-name {
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        @media screen and (max-width: 768px) {
          font-size: 16px;
          line-height: 19px;
          padding-bottom: 2px;
        }
      }
      .author-position {
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        @media screen and (max-width: 768px) {
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
  }
  .section-price {
    margin-bottom: 50px;
    @media screen and (max-width: 991px) {
      background: url('/images/price-bg.png') no-repeat bottom center;
    }
    .price-box {
      height: 336px;
      background: url('/images/price-bg.png') no-repeat bottom center;
      padding: 30px 100px;
      @media screen and (max-width: 991px) {
        height: auto;
        background: none;
        padding: 30px 50px;
      }
      @media screen and (max-width: 768px) {
        padding: 0px 0px 30px;
      }
    }
    .price-box-title {
      font-size: 32px;
      font-weight: 700;
      line-height: 36px;
      font-family: var(--font-lora);
      margin-bottom: 30px;
      @media screen and (max-width: 991px) {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 20px;
      }
      @media screen and (max-width: 768px) {
        font-size: 22px;
        line-height: 22px;
        margin-bottom: 40px;
      }
    }
    .price-box-features {
      column-count: 2;
      column-gap: 20px;
      font-size: 18px;
      @media screen and (max-width: 768px) {
        column-count: 1;
        font-size: 16px;
      }
    }
    .price-box-total-action {
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      align-items: center;
      @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 20px;
      }
    }
    .price-box-total {
      font-size: 32px;
      font-weight: 700;
      @media screen and (max-width: 991px) {
        font-size: 28px;
      }
      @media screen and (max-width: 768px) {
        font-size: 24px;
      }
    }
    .price-box-action {
      a {
        background-color: rgba(223, 121, 0, 1);
        border-radius: 10px;
        color: white;
        font-size: 18px;
        line-height: 22px;
        font-weight: 700;
        width: 300px;
        height: 62px;
        padding: 0 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        @media screen and (max-width: 991px) {
          font-size: 16px;
        }
        @media screen and (max-width: 479px) {
          width: 275px;
        }
      }
    }
  }
  .section-faq {
    margin-bottom: 50px;
    .section-title {
      font-size: 36px;
      font-weight: 600;
      line-height: normal;
      text-align: center;
      margin-bottom: 50px;
      @media screen and (max-width: 991px) {
        font-size: 28px;
        margin-bottom: 20px;
        text-align: left;
        padding-left: 11px;
      }
    }
    .faq-item {
      border-top: solid 1px #dcdcdc;
    }
    .faq-question {
      padding: 30px 11px;
      font-size: 18px;
      line-height: 22px;
      font-weight: 600;
    }
    .faq-answer {
      padding: 0px 11px 30px;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .footer-sticky-bar {
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    .container {
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: center;
      padding: 10px 20px;
    }
    .bar-title {
      font-size: 24px;
      line-height: 63px;
      font-weight: 500;
      @media screen and (max-width: 991px) {
        font-size: 18px;
        line-height: normal;
      }
      @media screen and (max-width: 768px) {
        font-size: 16px;
      }
    }
    .bar-button {
      background-color: rgba(223, 121, 0, 1);
      border-radius: 10px;
      color: white;
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
      width: 198px;
      height: 41px;
      padding: 0 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      white-space: nowrap;
      @media screen and (max-width: 768px) {
        font-size: 14px;
        padding: 0 12px;
      }
    }
  }
}
