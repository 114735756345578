.page-research-sudarshan-kriya {
  color: #31364e;
  .container {
    max-width: 1304px;
  }
  .section-title {
    line-height: 48px;
    font-size: 48px;
    font-family: var(--font-lora);
    @media (width <= 767px) {
      line-height: 42px;
      font-size: 38px;
    }
  }
  .research-banner {
    background: url(../../../public/images/banner-research-sudarshan-kirya.jpg)
      no-repeat 100% center;
    background-size: cover;
    height: 673px;
    @media (width <= 991px) {
      background: url(../../../public/images/research-banner-bg-mobile.png)
        no-repeat 0 100%;
      background-size: cover;
      height: 714px;
    }
    .container {
      padding: 0 40px;
      @media (width <= 991px) {
        padding-top: 12px;
        max-width: 350px;
        padding-left: 15px;
        padding-right: 15px;
        &::before {
          margin: auto;
          content: '';
          display: block;
          width: 242px;
          height: 242px;
          margin: auto;
          background: url(../../../public/images/research-banner-gurudev-mobile.jpg)
            no-repeat 0;
          background-size: contain;
          border-radius: 100%;
        }
      }
    }
    .banner-title {
      width: 376px;
      padding-top: 60px;
      font-size: 72px;
      line-height: 77px;
      @media (width <= 991px) {
        padding-top: 40px;
        font-size: 48px;
        line-height: 45px;
        text-align: center;
        width: 100%;
      }
    }
    .banner-description {
      line-height: 32px;
      font-size: 28px;
      padding-top: 30px;
      width: 430px;
      @media (width <= 991px) {
        line-height: 24px;
        font-size: 22px;
        padding-top: 20px;
        width: 100%;
      }
    }
    .key-highlights {
      padding-top: 30px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      @media (width <= 991px) {
        padding-top: 20px;
        gap: 10px;
      }
      .item {
        display: flex;
        gap: 20px;
        align-items: center;
        font-size: 20px;
        font-weight: 600;
        @media (width <= 991px) {
          gap: 10px;
          font-size: 17px;
          .research-banner-icon {
            width: 26px;
            height: 26px;
          }
        }
      }
    }
    .banner-action {
      padding-top: 40px;
      a {
        height: 64px;
        color: #ffffff;
        font-weight: 600;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        text-decoration: none;
        width: 276px;
        font-size: 22px;
        background-image: linear-gradient(
          rgb(255, 209, 111),
          rgb(255, 134, 91)
        );
        @media (width <= 991px) {
          height: 54px;
          font-weight: 500;
        }
      }
    }
  }
  .featured-in {
    .container {
      padding-bottom: 70px;
      padding-top: 70px;
    }
    .featured-in-image {
      margin: auto;
      @media (width <= 768px) {
        max-width: 314px;
      }
    }
  }
  .section-breathing-hack {
    padding-bottom: 70px;
    .section-title {
      text-align: center;
      margin-bottom: 50px;
      @media (width <= 767px) {
        margin-bottom: 30px;
      }
    }
    .video-container {
      width: 698px;
      height: 393px;
      margin: auto;
      @media (width <= 767px) {
        max-width: 100%;
        aspect-ratio: 3 / 2;
        height: unset;
      }
      iframe {
        width: 100%;
        height: 100%;
      }
    }
    .video-content {
      width: 698px;
      margin: auto;
      margin-top: 40px;
      font-size: 20px;
      line-height: 30px;
      font-weight: 400;
      @media (width <= 767px) {
        font-size: 15px;
        line-height: 22px;
        margin-top: 20px;
        width: 100%;
      }
      a {
        color: rgb(237, 153, 78);
        font-weight: 400;
      }
      p:last-child {
        margin: 0;
      }
    }
  }
  .section-modern-science {
    .container {
      padding: 0;
    }
    .box-modern-science {
      background-image: linear-gradient(rgb(255, 161, 128), rgb(237, 115, 71));
      border-radius: 32px;
      @media (width <= 767px) {
        border-radius: 0;
      }
    }
    .box-inner {
      padding: 44px;
      display: flex;
      gap: 50px;
      background: url(../../../public/images/ancient-breath-vector.png)
        no-repeat bottom;
      background-size: 1092px 379px;
      align-items: center;
      @media (width <= 767px) {
        flex-direction: column;
        gap: 20px;
        padding: 44px 15px;
      }
      & > .section-title {
        color: white;
        font-size: 30px;
        line-height: 34px;
        @media screen and (min-width: 768px) {
          display: none;
        }
      }
    }
    .box-image {
      flex-shrink: 0;
      @media (768px <= width <= 1024px) {
        flex-shrink: unset;
        aspect-ratio: 2/1;
      }
      img {
        border-radius: 12px;
      }
    }
    .box-content {
      color: white;
      .section-title {
        line-height: 58px;
        margin-bottom: 30px;
        @media (width <= 767px) {
          display: none;
        }
      }
      p {
        font-size: 20px;
        line-height: 29px;
        max-width: 485px;
        @media (width <= 767px) {
          font-size: 16px;
          line-height: 22px;
        }
        &:first-child {
          margin-bottom: 30px;
        }
        strong {
          font-weight: 600;
        }
      }
    }
  }
  .section-evidence {
    padding-top: 80px;
    padding-bottom: 60px;
    @media (width <= 767px) {
      padding-top: 20px;
      padding-bottom: 30px;
    }
    .container {
      padding: 0;
    }
    .section-title {
      line-height: 58px;
      text-align: center;
      padding-bottom: 27px;
      @media (width <= 767px) {
        font-size: 30px;
        line-height: 36px;
        padding: 0;
      }
    }
    .university-image {
      margin: 40px auto;
      @media (width <= 767px) {
        margin: 20px auto 30px;
      }
    }
    .evidence-text {
      font-size: 20px;
      line-height: 29px;
      text-align: center;
      max-width: 855px;
      margin: auto;
      padding: 8px 20px 0;
      @media (width <= 767px) {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
  .section-impact {
    background: #fffaf5;
    padding: 50px 0 100px 0;
    @media (width <= 767px) {
      padding: 30px 0 50px 0;
    }
    .section-title {
      font-size: 36px;
      line-height: 45px;
      text-align: center;
      margin-bottom: 20px;
      font-weight: 600;
      color: #000;
      @media (width <= 767px) {
        font-size: 26px;
        line-height: 38px;
        text-align: left;
      }
    }
    .section-desc {
      line-height: 24px;
      text-align: center;
      font-size: 16px;
      @media (width <= 767px) {
        font-size: 15px;
        line-height: 22px;
        text-align: left;
      }
      a {
        color: #ed994e;
        font-weight: 500;
      }
    }
    .impact-stats {
      gap: 20px;
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      padding-top: 60px;
      @media (width <= 767px) {
        padding-top: 30px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 15px;
      }
      .item {
        padding: 20px 0;
        background: white;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        min-height: 186px;
        align-items: center;
        @media (width <= 767px) {
          img {
            width: 24px;
          }
        }
        .first-row {
          display: flex;
          align-items: center;
          gap: 12px;
          justify-content: center;
        }
        .item-number {
          color: #ff7e00;
          font-size: 48px;
          font-weight: 600;
          @media (width <= 767px) {
            font-size: 38px;
          }
        }
        .second-row {
          font-size: 18px;
          line-height: 26px;
          text-align: center;
          max-width: 260px;
          @media (width <= 767px) {
            font-size: 15px;
            line-height: 22px;
          }
          a {
            color: #ff7e00;
            font-weight: 500;
          }
        }
      }
    }
  }
  .section-about-sky {
    padding-top: 40px;
    @media (width <= 767px) {
      padding-top: 0;
    }
    .section-title {
      text-align: center;
      margin-bottom: 30px;
      @media (width <= 767px) {
        font-size: 32px;
        line-height: 38px;
      }
    }
    .about-video-container {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      margin-bottom: 100px;
      @media (width <= 767px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        margin-bottom: 50px;
        iframe {
          max-width: 100%;
          aspect-ratio: 3 / 2;
          height: unset;
          margin: auto;
        }
      }
      &:nth-of-type(odd) {
        .video-content {
          order: -1;
          padding-left: 0;
          padding-right: 20px;
          @media (width <= 767px) {
            order: unset;
            padding: 0;
          }
        }
        .video-title {
          max-width: unset;
        }
      }
    }
    .video-container {
      iframe {
        max-width: 100%;
      }
    }
    .video-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 20px;
      @media (width <= 767px) {
        padding: 0;
      }
    }
    .video-title {
      font-size: 36px;
      line-height: 42px;
      max-width: 494px;
      @media (width <= 767px) {
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        padding-top: 20px;
        max-width: unset;
      }
    }
    .video-author {
      font-size: 20px;
      margin-bottom: 10px;
      margin-top: 30px;
      font-weight: 500;
      @media (width <= 767px) {
        text-align: center;
      }
    }
    .video-author-position {
      font-size: 16px;
      color: #6f7283;
      @media (width <= 767px) {
        text-align: center;
      }
    }
  }
  .section-mindfulness {
    background-image: linear-gradient(
      rgb(255, 255, 255),
      rgb(252, 225, 160) 70%,
      rgb(255, 255, 255)
    );
    padding: 100px 0;
    @media (width <= 767px) {
      padding: 50px 0;
    }
    .container {
      max-width: 1080px;
    }
    .mindfulness-row {
      display: flex;
      gap: 60px;
      @media (width <= 767px) {
        flex-direction: column;
        gap: 24px;
      }
    }
    .mindfulness-col-1 {
      padding-left: 30px;
      padding-right: 20px;
      @media (width <= 767px) {
        padding: 0;
        p {
          margin-bottom: 0;
          margin-top: 20px;
        }
        p:last-child {
          display: none;
        }
      }
    }
    .title {
      font-size: 36px;
      line-height: 36px;
      font-weight: 600;
      @media (width <= 767px) {
        font-size: 32px;
      }
    }
    p {
      margin-top: 30px;
      font-size: 18px;
      line-height: 24px;
      @media (width <= 767px) {
        margin-top: 20px;
        font-size: 16px;
        line-height: 22px;
        margin: 0;
      }
      a {
        color: #3d8be8;
      }
    }
    .mindfulness-mobile-col {
      @media screen and (min-width: 768px) {
        display: none;
      }
    }
    .mindfulness-video-thumb {
      position: relative;
      width: 370px;
      @media (width <= 767px) {
        margin: auto;
        max-width: 100%;
      }
      .absolute {
        position: absolute;
        bottom: 0;
        right: 0;
        cursor: pointer;
      }
    }
  }
  .section-people-sharing {
    margin-bottom: 100px;
    .container {
      max-width: 1144px;
    }
    .section-title {
      text-align: center;
      margin-bottom: 50px;
      @media (width <= 767px) {
        font-size: 32px;
        line-height: 38px;
      }
    }
    .testimonials {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 120px;
      text-align: center;
      @media (width <= 767px) {
        gap: 60px;
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }
    }
    .testimony-text {
      font-size: 20px;
      line-height: 26px;
      @media (width <= 767px) {
        font-size: 18px;
        line-height: 22px;
      }
    }
    .testimony-author {
      padding-top: 20px;
      font-size: 20px;
      font-weight: 700;
      @media (width <= 767px) {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
  .section-aol-stats {
    margin-bottom: 100px;
    @media (width <= 767px) {
      margin-bottom: 50px;
    }
    .stats-listing {
      max-width: 1000px;
      margin: auto;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 40px;
      @media (width <= 767px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        max-width: 320px;
        gap: 30px;
      }
    }
    .stats-item {
      display: flex;
      gap: 40px;
      align-items: center;
      @media (width <= 767px) {
        gap: 15px;
      }
    }
    .stat-icon {
      width: 88px;
      flex-shrink: 0;
      @media (width <= 767px) {
        width: 55px;
      }
    }
    .stat-content {
      display: flex;
      flex-direction: column;
      gap: 10px;
      @media (width <= 767px) {
        gap: 0;
      }
    }
    .stat-title {
      font-size: 48px;
      line-height: 35px;
      font-weight: 600;
      @media (width <= 767px) {
        font-size: 28px;
      }
      span {
        font-size: 32px;
        @media (width <= 767px) {
          font-size: 20px;
        }
      }
    }
    .stat-desc {
      font-size: 32px;
      line-height: 32px;
      color: #898989;
      font-weight: 500;
      @media (width <= 767px) {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
  .section-gurudev-quote {
    background: linear-gradient(rgb(255, 255, 255), rgb(250, 233, 216));
    .container {
      min-height: 409px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (width <= 767px) {
        min-height: 350px;
      }
    }
    .quote-text {
      max-width: 1071px;
      margin: 0 auto;
      font-size: 36px;
      line-height: 45px;
      text-align: center;
      @media (width <= 767px) {
        font-size: 25px;
        line-height: 32px;
      }
    }
    .quote-author {
      padding-top: 30px;
      font-size: 24px;
      text-align: center;
      @media (width <= 767px) {
        font-size: 18px;
        padding-top: 50px;
      }
    }
  }
  .section-ready-to-find {
    padding-top: 80px;
    @media (width <= 767px) {
      padding-top: 40px;
    }
    .find-out-box {
      background: linear-gradient(rgb(255, 161, 128), rgb(230, 102, 69));
      border-radius: 32px;
      height: 459px;
      @media (width <= 767px) {
        height: unset;
      }
      &-inner {
        background-image: url(../../../public/images/left-thread.png),
          url(../../../public/images/right-thread.png);
        background-position:
          37px 50px,
          calc(100% - 37px) 50px;
        background-repeat: no-repeat, no-repeat;
        height: 100%;
        padding-top: 45px;
        padding-bottom: 45px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: column;
        @media (width <= 767px) {
          background: none;
          padding: 45px 20px 35px 20px;
        }
      }
    }
    .find-out-text {
      font-size: 48px;
      line-height: 58px;
      color: #fff;
      text-align: center;
      max-width: 621px;
      @media (width <= 767px) {
        font-size: 32px;
        line-height: 36px;
      }
    }
    .find-out-action-wrap {
      padding-top: 80px;
      @media (width <= 767px) {
        padding-top: 40px;
      }
      .btn {
        font-size: 22px;
        width: 294px;
        background-color: #fff;
        height: 64px;
        color: #e66645;
        font-weight: 600;
        border-radius: 50px;
        line-height: 100%;
        line-height: 50px;
        @media (width <= 767px) {
          font-size: 18px;
          width: 260px;
          height: 54px;
          line-height: 40px;
        }
      }
    }
  }
  .section-faq {
    padding: 100px 0;
    .subtitle {
      color: #ed994e;
      font-size: 18px;
      line-height: 20px;
      font-weight: 600;
      text-align: center;
      @media (width <= 767px) {
        font-size: 16px;
        margin-bottom: 5px;
      }
    }
    .section-title {
      font-size: 48px;
      line-height: 58px;
      text-align: center;
      @media (width <= 767px) {
        font-size: 32px;
        line-height: 38px;
      }
    }
    #faq {
      padding: 0;
      li {
        position: relative;
        overflow: hidden;
        padding: 20px;
        margin: 0;
        margin-bottom: 15px;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0 5px 3px -2px rgba(0, 0, 0, 0.1);
        -webkit-tap-highlight-color: transparent;
        input[type='checkbox'] {
          position: absolute;
          cursor: pointer;
          width: 100%;
          height: 100%;
          z-index: 1;
          opacity: 0;
          touch-action: manipulation;
          top: 0;
          left: 0;
          &:checked {
            & + h2 + p {
              display: block;
            }
            & + h2 {
              i:before {
                transform: translate(-2px, 0) rotate(45deg);
              }
              i:after {
                transform: translate(2px, 0) rotate(-45deg);
              }
            }
          }
        }
        i {
          position: absolute;
          transform: translate(-6px, 0);
          margin-top: 28px;
          right: 15px;
          top: 20px;
          @media (max-width: 767px) {
            top: 15px;
          }
          &:before,
          &:after {
            content: '';
            position: absolute;
            width: 3px;
            height: 9px;
            color: #6f7283;
            transition: all 0.3s;
          }
          &:before {
            transform: translate(2px, 0) rotate(45deg);
            background: #31364e;
          }
          &:after {
            transform: translate(-2px, 0) rotate(-45deg);
            background: #31364e;
          }
        }
      }
      h2 {
        font-size: 28px;
        line-height: 36x;
        text-align: left;
        padding: 15px 30px 0;
        font-weight: 500;
        margin: 0;
        @media (max-width: 767px) {
          font-size: 20px;
          line-height: 26px;
          padding-left: 10px;
        }
      }
      p {
        color: #6f7283;
        text-align: left;
        font-size: 24px;
        line-height: 33px;
        position: relative;
        z-index: 2;
        padding: 6px 50px 0 31px;
        display: none;
        @media (max-width: 767px) {
          font-size: 16px;
          line-height: 22px;
          padding-left: 10px;
        }
      }
    }
  }
  .section-research-benefits {
    padding: 50px 0 40px 0;
    @media (width <= 767px) {
      padding-top: 20px;
      padding-bottom: 0;
    }
    .container {
      max-width: 1182px;
      margin: auto;
    }
    .section-title {
      line-height: 58px;
      text-align: center;
      max-width: 865px;
      margin: auto;
      @media (width <= 767px) {
        line-height: 38px;
        font-size: 32px;
      }
    }
    .section-desc {
      text-align: center;
      max-width: 816px;
      margin: auto;
      margin-top: 30px;
      @media (width <= 767px) {
        margin-top: 15px;
        font-size: 16px;
        line-height: 22px;
      }
    }
    .research-benefit-tiles {
      margin-top: 30px;
      .slick-slider {
        padding-bottom: 113px;
      }
      .slick-arrow {
        bottom: 40px;
        top: auto;
        background: url(../../../public/images/ic-back-talk.svg) no-repeat
          center;
        height: 33px;
        width: 33px;
        background-size: 33px;
        left: auto;
        right: 40px;
        font-size: 0;
        &::before {
          display: none;
        }
        &.slick-disabled {
          opacity: 0.3;
        }
        &.slick-prev {
          right: 88px;
        }
        &.slick-next {
          transform: rotateY(190deg);
          bottom: 57px;
        }
      }
    }
    .flip-card {
      background-color: transparent;
      //perspective: 1000px;
    }

    .flip-card-inner {
      position: relative;
      width: 100%;
      height: 100%;
      transition: transform 0.6s;
      transform-style: preserve-3d;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      min-height: 340px;
      min-width: 250px;
      max-width: 240px;
    }

    .flip-card:hover .flip-card-inner {
      transform: rotateY(180deg);
    }

    .flip-card-hidden {
      transform: rotateY(0deg);
    }
    .flip-card-front {
      padding-left: 24px;
      padding-right: 24px;
    }
    .flip-card-back {
      padding-left: 18px;
      padding-right: 18px;
    }
    .flip-card-front,
    .flip-card-back {
      position: absolute;
      height: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transform: rotateY(0deg);
      .title {
        font-size: 24px;
        line-height: 24px;
        font-weight: 500;
      }
      .desc {
        font-size: 16px;
        line-height: 24px;
        padding-top: 24px;
      }
    }

    .flip-card-back {
      transform: rotateY(180deg);
      .title {
        padding-top: 24px;
      }
    }
  }
  .modal-overlay {
    &.modal--youtube {
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      .modal-content {
        max-width: 1024px;
        margin: auto;
        aspect-ratio: 16 / 9;
        padding: 4px;
        .video-wrapper {
          width: 100%;
          height: 100%;
          overflow: hidden;
          border-radius: 14px;
        }
        iframe {
          width: 100%;
          height: 100%;
        }
      }
      .close-button {
        position: absolute;
        right: 20px;
        top: 10px;
        z-index: 10;
      }
    }
  }
}
