.header {
  background: #fff;
  border-bottom: 1px solid #e9e9e9;
  .logo {
    @media (width <= 991px) {
      margin: auto;
    }
  }
  @media (width <= 991px) {
    position: relative !important;
    &.menu-opened {
      .navbar-toggler {
        position: fixed;
        right: 15px;
      }
    }
  }
}
.header__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 0;
  max-width: 1140px;
  align-items: center;
  @media (width <= 991px) {
    justify-content: space-between;
    padding: 8px 15px;
  }
  .header__button {
    border: #31364e 1px solid;
    height: 40px;
    border-radius: 40px;
    padding: 10px 24px;
    font-size: 15px;
    line-height: 20px;
  }
  .user-profile-link {
    position: relative;
    img {
      border: 0;
      width: 24px;
      margin-right: 0.5rem;
    }
    .UserprofileView {
      display: flex;
      align-items: center;
      .username {
        font-size: 16px;
        font-weight: 600;
        @media screen and (max-width: 1199px) {
          display: none;
        }
      }
      .header__button {
        height: 40px !important;
        font-size: 15px !important;
        font-weight: 500;
        border-radius: 40px;
        padding: 0 24px;
        white-space: nowrap;
        @media (width >= 992px) and (width <= 1199px) {
          padding: 0 10px;
        }
      }
    }
    .header_profileHeaderImage {
      border-radius: 50%;
      box-shadow: 0 2px 25px 0 rgba(61, 139, 232, 0.2);
      color: #ffffff;
      font-family: Lora, sans-serif;
      font-size: 16px;
      font-weight: 700;
      height: 2.375rem;
      margin: 4px 10px 0;
      width: 2.375rem;
      img {
        width: 2.375rem;
        height: 2.375rem;
        margin-right: 0;
      }
    }
    .initials {
      display: flex;
      position: absolute;
      width: 2.375rem;
      height: 2.375rem;
      justify-content: center;
      align-items: center;
      background-color: #ef6c00;
      border-radius: 50%;
    }
    @media screen and (max-width: 991px) {
      order: 1;
      margin: unset;
      padding: 0;
      &.hide-link {
        display: none;
      }
    }
  }
  @media (width >= 992px) {
    .mobile-menu-buttons {
      display: none;
    }
    .navbar-collapse.collapse {
      .nav-logowrap {
        display: none;
      }
    }
  }
  @media (width <= 991px) {
    .user-profile-link {
      display: none;
    }
  }
}
.navbar {
  margin: auto;
  padding: 0;
  z-index: 1;
  @media (width >= 992px) and (width <= 1199px) {
    flex: 1;
  }
  .navbar-nav {
    display: flex;
    gap: 28px;
    z-index: 1;
    @media (width >= 992px) and (width <= 1199px) {
      flex: 1;
      gap: 0;
      justify-content: space-evenly;
    }
    @media (width >= 992px) {
      height: 38px;
    }
    @media (width <= 991px) {
      max-height: calc(100vh - 180px);
      overflow: auto;
      padding: 38px 20px;
      width: 100% !important;
      gap: 0;
    }
    li.nav-item {
      @media (width <= 991px) {
        padding-top: 14px;
        padding-bottom: 14px;
        border-bottom: #e9e9e9 1px solid;
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }
      }
      @media (width >= 992px) {
        height: 68px;
        .dropdown-menu {
          padding: 12px 16px;
          top: 64px;
          background-color: $white;
          border-radius: 16px;
          border: none;
          margin-top: 0;
          transform: translateX(-33.33%);
          .back-link {
            display: none;
          }
          .dropdown-item {
            white-space: nowrap;
            &:hover {
              background-color: transparent;
              color: #ff9200;
            }
            &.active {
              background-color: transparent;
              color: #ff9200;
            }
            &.help {
              color: #3d8be8;
            }
          }
        }
        .dropdown-menu-col:has(.dropdown-menu-inner) {
          display: flex;
        }
        .dropdown-menu-inner {
          display: flex;
        }
        &:hover {
          .dropdown-menu {
            display: flex !important;
          }
        }
      }
      .dropdown-header {
        color: #6f7283;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        padding: 8px 16px;
        text-transform: uppercase;
        font-family: var(--font-work-sans);
      }
      @media (width <= 991px) {
        .dropdown-menu {
          border: none;
          border-radius: 0;
          box-shadow: none;
          display: block;
          height: 100vh;
          left: 100%;
          padding-top: 0px;
          margin-top: 0px;
          position: fixed;
          top: 0;
          transition: all 0.3s ease-in-out;
          width: 100%;
          z-index: 1;
          &::before {
            content: '';
            display: block;
            height: 67px;
            left: 0;
            position: sticky;
            top: 0;
            width: 100%;
            background: rgb(255, 255, 255);
            background: linear-gradient(
              0deg,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 1) 35%,
              rgba(255, 255, 255, 1) 100%
            );
          }
          &-inner {
            flex-direction: column;
            overflow: auto;
            height: 100%;
            &:last-child {
              padding-bottom: 50px;
            }
            &:not(:has(.dropdown-header)) {
              &::before {
                content: attr(data-title);
                padding: 0 20px;
                line-height: 28px;
                font-size: 20px;
                font-weight: 400;
              }
            }
          }
        }
        .back-link {
          background-color: transparent;
          border: none;
          display: block;
          left: 100%;
          padding: 0;
          position: fixed;
          top: 20px;
          z-index: 1;
          transition: all 0.3s ease-in-out;
          &::after {
            display: none;
          }
        }
        .dropdown-menu-col {
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;
          &::before {
            content: attr(data-title);
            padding: 0 20px;
            margin-bottom: 12px;
            line-height: 28px;
            font-size: 20px;
            font-weight: 400;
          }
          .dropdown-item {
            margin: 4px 0;
            padding: 0 20px;
            &.active {
              background: none;
              color: #ff9200;
            }
            &.help {
              color: #3d8be8;
            }
          }
        }
        &.show {
          .dropdown-menu.show {
            left: 56px;
            overflow: auto;
          }
          .back-link {
            left: 76px;
          }
        }
      }
    }
    .nav-link {
      font-size: 15px;
      color: $txt-color;
      padding: 8px;
      @media (width <= 991px) {
        font-size: 20px !important;
        font-weight: 400;
        line-height: 28px;
        padding: 0 !important;
        display: flex;
        justify-content: space-between;
      }
      &.dropdown-toggle {
        &::after {
          display: none;
          @media (width <= 991px) {
            background: url(/images/BackArrow.svg) no-repeat center;
            height: 20px;
            width: 20px;
            border: none;
            transform: rotate(180deg);
            margin: 0;
            display: block;
          }
        }
      }
    }
  }
  @media (width <= 991px) {
    margin-left: 0;
    margin-right: unset;
    .navbar-collapse {
      position: fixed;
      width: calc(100vw - 56px);
      left: 100%;
      top: 0;
      background-color: #ffffff;
      transition: all 0.3s ease;
      height: 100vh !important;
      overflow: auto;
      display: unset;
      &.show {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        left: 56px;
      }
      .nav-logowrap {
        padding-left: 20px;
        padding-top: 8px;
      }
      .mobile-menu-buttons {
        width: 100%;
        padding: 0 20px 70px;
        margin-top: auto;
        button {
          width: 100%;
        }
      }
    }
    &:has(.navbar-collapse.collapse.show) {
      &::before {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        content: '';
        display: block;
      }
    }
    .navbar-toggler {
      &-header {
        background: transparent !important;
        border: none;
        z-index: 11;
        padding: 4px 12px;
        &:focus {
          outline: none;
          box-shadow: none;
        }
        .icon-bar {
          background-color: #000;
          border-radius: 3px;
          display: block;
          height: 2px;
          transition: all 0.2s;
          width: 25px;
          & + .icon-bar {
            margin-top: 5px;
          }
        }
        &:not(.collapsed) {
          .top-bar {
            transform: rotate(45deg);
            transform-origin: 10% 10%;
          }
          .middle-bar {
            opacity: 0;
          }
          .bottom-bar {
            transform: rotate(-45deg);
            transform-origin: 10% 90%;
          }
        }
      }
    }
  }
}
