.section-hero {
  padding: 20px 0;
  @media (width <= 767px) {
    padding: 0;
  }
  .container {
    @media (width <= 991px) {
      padding: 0;
    }
  }
  .slide-wrap {
    position: relative;
  }
  .slick-slide {
    @media (width >= 768px) {
      aspect-ratio: 1140/530;
    }
  }
  .slide-image {
    @media (width <= 767px) {
      height: 700px;
    }
  }
  .slide-content {
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 76px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $white;
    max-width: 650px;
    @media (width <= 767px) {
      top: unset;
      bottom: 0;
      height: auto;
      padding: 0 30px 100px;
      max-width: 100%;
    }
  }
  .slide-title {
    font-size: 56px;
    font-weight: 400;
    line-height: 50px;
    > div {
      font-weight: 700;
    }
    &.inline {
      display: flex;
      gap: 15px;
    }
    @media (width <= 767px) {
      font-size: 36px;
      line-height: 36px;
    }
  }
  .slide-subtitle {
    margin-top: 36px;
    font-size: 18px;
    font-weight: 700;
    @media (width <= 767px) {
      margin-top: 15px;
    }
  }
  .slide-text {
    font-size: 18px;
    margin-bottom: 24px;
    margin-top: 24px;
    max-width: 345px;
    @media (width <= 767px) {
      max-width: unset;
    }
  }
  .slide-wrap.happines-week {
    .slide-text {
      max-width: 522px;
    }
  }
  .slide-date {
    font-size: 28px;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: 30px;
    @media (width <= 767px) {
      font-size: 20px;
    }
  }
  .slide-action {
    a {
      color: $white;
      text-decoration: none;
    }
    @media (width <= 767px) {
      button {
        width: 100%;
      }
    }
  }
  .slick-next,
  .slick-prev {
    display: none !important;
  }
  .slick-dots {
    @media (width <= 767px) {
      bottom: 75px;
    }
    li {
      margin: 0;
      button {
        &:before {
          font-size: 10px;
          margin-top: 20px;
        }
      }
    }
  }
}
.section-gurudev {
  margin-bottom: 112px;
  background: linear-gradient(#ffffff, #fff7e3, #ffffff);
  .subsection-gurudev-inspire {
    display: flex;
    margin-bottom: 44px;
    justify-content: space-between;
    @media (width <= 767px) {
      flex-direction: column;
      margin: 20px 9px;
      padding-bottom: 24px;
    }
    .section-content {
      margin-top: 60px;
      max-width: 480px;
      @media (width <= 767px) {
        margin-top: 0;
        padding: 24px;
        .section-action {
          display: none;
        }
      }
    }
    .section-image {
      flex-shrink: 0;
      padding: 0 42px;
      @media (width <= 767px) {
        padding: 0;
        display: flex;
        justify-content: center;
      }
    }
    .section-title {
      font-size: 32px;
      line-height: 30px;
      strong {
        font-weight: 700;
        @media (width <= 767px) {
          display: block;
        }
      }
    }
    .section-subtitle {
      font-size: 18px;
      line-height: 26px;
      font-weight: 700;
      margin-top: 22px;
    }
    .section-action {
      margin-top: 26px;
      a {
        color: $black;
        text-decoration: none;
      }
      button {
        font-weight: 700;
        letter-spacing: 0;
      }
    }
    .section-action-mobile {
      padding: 0 48px;
      button {
        width: 100%;
        margin-top: 26px;
      }
      @media (width >= 768px) {
        display: none;
      }
    }
  }
  .subsection-aol-courses {
    @media (width <= 767px) {
      padding-top: 28px;
    }
    .section-title {
      line-height: 35px;
      font-size: 30px;
      padding-bottom: 16px;
      text-align: center;
      @media (width <= 767px) {
        text-align: left;
        padding: 0 0 32px 24px;
        strong {
          display: block;
        }
      }
    }
    .section-subtitle {
      line-height: 35px;
      font-size: 20px;
      text-align: center;
      @media (width <= 767px) {
        display: none;
      }
    }
    .aol-courses-listing {
      padding-top: 64px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
      @media (width <= 767px) {
        padding-top: 0;
      }
      .course-item {
        position: relative;
        max-width: 570px;
        box-shadow:
          0px 0px 15px 0px rgba(0, 0, 0, 0.03),
          0px 2px 30px 0px rgba(0, 0, 0, 0.08),
          0px 0px 1px 0px rgba(0, 0, 0, 0.3);
        @media (width <= 767px) {
          max-width: 100%;
          width: 100%;
          height: 550px;
          overflow: hidden;
          &:nth-child(1) {
            button.section-action-btn {
              background-image: linear-gradient(
                to right,
                rgba(86, 164, 255, 0.6),
                rgb(15, 111, 226)
              );
              border: none;
            }
          }
          &:nth-child(2) {
            button.section-action-btn {
              background-image: linear-gradient(
                to right,
                rgba(116, 100, 214, 0.6),
                rgb(116, 100, 214)
              );
              border: none;
            }
          }
          &:nth-child(3) {
            button.section-action-btn {
              background-image: linear-gradient(
                to right,
                rgba(0, 152, 181, 0.6),
                rgb(0, 152, 181)
              );
              border: none;
            }
          }
          &:nth-child(4) {
            button.section-action-btn {
              background-image: linear-gradient(
                to right,
                rgba(105, 168, 47, 0.6),
                rgb(105, 168, 47)
              );
              border: none;
            }
          }
        }
      }
      .course-image {
        @media (width <= 767px) {
          img {
            width: 100%;
          }
        }
      }
      .course-info {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 48px 24px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (width <= 767px) {
          text-align: left;
          align-items: flex-start;
        }
      }
      .course-title {
        font-size: 36px;
        font-weight: 600;
        margin-bottom: 68px;
        max-width: 280px;
        line-height: 36px;
        @media (width <= 767px) {
          max-width: 100%;
        }
      }
      .course-text {
        line-height: 20px;
        padding: 4px;
        max-width: 420px;
        @media (width <= 767px) {
          max-width: 100%;
        }
      }
      .course-action {
        padding-top: 24px;
        a {
          color: $white;
          text-decoration: none;
        }
        @media (width <= 767px) {
          width: 100%;
        }
        button {
          font-size: 16px;
          @media (width <= 767px) {
            width: 100%;
          }
        }
      }
    }
  }
}
.section-home-testimonies {
  background: linear-gradient(#ffffff, #fce1a0, #ffffff);
  .container {
    max-width: 1470px;
  }
  .section-title {
    font-size: 36px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 40px;
    @media (width <= 767px) {
      font-size: 28px;
      line-height: 35px;
      text-align: left;
      width: 90%;
      padding: 0 32px 0 17px;
    }
  }
  .slick-slide {
    padding: 0 8px;
    opacity: 0.6;
    @media (width >= 768px) {
      width: 500px;
    }
    @media (width >= 640px) and (width <= 767px) {
      width: 400px;
    }
    @media (width <= 639px) {
      width: 320px;
    }
    &.slick-center {
      opacity: 1;
    }
  }
  .slide-wrap {
    border-bottom: rgb(255, 156, 0) 8px solid;
    background: $white;
    padding: 48px;
  }
  .slide-title {
    font-family: var(--font-lora);
    font-size: 36px;
    line-height: 36px;
    background: url(../../../public/images/quoteIcon.webp) no-repeat top right;
    padding-right: 76px;
    @media (width <= 767px) {
      font-size: 24px;
      line-height: 28px;
      font-weight: 600;
    }
  }
  .slide-text {
    padding: 24px 0;
    font-family: var(--font-lora);
    font-style: italic;
    font-size: 18px;
    line-height: 27px;
    .readMore-link {
      font-style: normal;
      cursor: pointer;
      &:hover {
        color: $brand-orange;
      }
    }
  }
  .slide-author-info {
    display: flex;
    gap: 16px;
    align-items: center;
    .author-image {
      img {
        border-radius: 100%;
      }
    }
    .author-content {
      font-size: 14px;
      line-height: 21px;
    }
    .author-name {
      font-weight: 600;
    }
    .author-position {
      color: rgb(111, 111, 111);
    }
  }
  .slick-next,
  .slick-prev {
    display: none !important;
  }
  .slick-dots {
    li {
      margin: 0;
      button {
        &:before {
          font-size: 10px;
          margin-top: 20px;
        }
      }
    }
  }
}
.section-media-words {
  margin-top: 84px;
  @media (width <= 767px) {
    padding: 0 15px;
  }
  .section-title {
    font-size: 36px;
    text-align: center;
    margin-bottom: 40px;
    @media (width <= 767px) {
      font-size: 28px;
      line-height: 30px;
      text-align: left;
      strong {
        display: block;
      }
    }
  }
  .media-houses {
    display: flex;
    justify-content: center;
    gap: 30px;
    @media (width <= 991px) {
      max-width: 550px;
      flex-wrap: wrap;
      margin-left: auto;
      margin-right: auto;
    }
    @media (width <= 767px) {
      max-width: 100%;
      flex-direction: column;
      gap: 0;
    }
    .media-item {
      max-width: 200px;
      @media (width <= 991px) {
        width: 250px;
        max-width: 100%;
      }
      @media (width <= 767px) {
        display: flex;
        width: 100%;
        max-width: unset;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 33px 0;
        &:last-child {
          border: none;
        }
      }
    }
    .media-logo {
      height: 98px;
      @media (width <= 767px) {
        flex: 0.5;
        height: unset;
      }
    }
    .media-text {
      font-family: var(--font-lora);
      font-size: 18px;
      line-height: 27px;
      @media (width <= 767px) {
        flex: 0.7;
      }
    }
  }
}
.section-home-service-projects {
  background: linear-gradient(#ffffff, #fff7e3, #ffffff);
  .container {
    padding-top: 180px;
    padding-bottom: 180px;
    position: relative;
    @media (width <= 767px) {
      padding-top: 80px;
    }
    &::before {
      background: url(../../../public/images/semi-circle.webp) no-repeat 0 0;
      content: '';
      position: absolute;
      top: 0;
      right: -50px;
      display: block;
      width: 400px;
      height: 290px;
      @media (width <= 1260px) {
        right: 60px;
      }
      @media (width <= 767px) {
        width: 200px;
        height: 145px;
        right: 0;
        top: 20px;
        background-size: contain;
      }
    }
  }
  .section-title {
    font-size: 36px;
    text-align: center;
    line-height: 32px;
    @media (width <= 767px) {
      font-size: 28px;
      text-align: left;
      padding: 0 24px 0 33px;
      strong {
        display: block;
      }
    }
  }
  .section-subtitle {
    font-size: 18px;
    line-height: 20px;
    max-width: 630px;
    padding: 0 24px;
    text-align: center;
    margin: 0 auto;
    @media (width <= 767px) {
      text-align: left;
      margin: 0;
      padding: 0 24px 30px 33px;
    }
  }
  .slick-slide {
    @media (width <= 767px) {
      .slide-image {
        img.sp-slide-image {
          width: 300px;
          height: 380px;
          object-fit: cover;
        }
      }
    }
  }
  .slide-wrap {
    position: relative;
    padding: 8px;
  }
  .slide-content {
    position: absolute;
    top: 0;
    left: 0;
    padding: 32px 60px 45px 43px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: $white;
    max-width: 580px;
    @media (width <= 767px) {
      padding: 38px 26px 32px 30px;
      max-width: 100%;
    }
  }
  .slide-title {
    font-family: var(--font-lora);
    font-size: 46px;
    line-height: 47px;
    max-width: 325px;
    @media (width <= 767px) {
      font-size: 30px;
      line-height: 35px;
    }
  }
  .slide-text-count {
    font-size: 36px;
    line-height: 32px;
    font-weight: 500;
    margin-bottom: 8px;
    @media (width <= 767px) {
      font-size: 24px;
      line-height: 28px;
    }
  }
  .slide-text {
    font-size: 18px;
    line-height: 20px;
    @media (width <= 767px) {
      font-size: 14px;
      line-height: 14px;
    }
  }
  .slick-next,
  .slick-prev {
    display: none !important;
  }
  .slick-dots {
    li {
      margin: 0;
      button {
        &:before {
          font-size: 10px;
          margin-top: 0px;
        }
      }
    }
  }
}
