h1,
h2,
h3,
h4,
h5,
h6,
.font-lora {
  font-family: var(--font-lora);
}
.font-work-sans {
  font-family: var(--font-work-sans);
}
