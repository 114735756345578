@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'aolicon';
@import 'bootstrap/scss/bootstrap'; // Import Bootstrap SCSS
@import 'base/reset';
@import 'abstracts/variables';
@import 'base/typography';
@import 'base/base';
@import 'components/buttons';
@import 'layout/header';
@import 'layout/footer';
@import 'pages/home';
@import 'pages/about-us';
@import 'pages/local-centers';
@import 'components/getStartedFloating';
@import 'components/loading-overlay';
@import 'components/loading';
@import 'pages/podcasts';
@import 'pages/gurudev';
@import 'pages/research';
@import 'pages/premiumcourse';
@import 'pages/sahajsamadhi';
@import 'pages/online-foundation-program';

:root {
  --background: #ffffff;
  --foreground: #171717;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #ffffff;
    --foreground: #171717;
  }
}

body {
  color: $txt-color;
  background: var(--background);
  font-family: var(--font-work-sans);
}

@layer utilities {
  .tw-text-balance {
    text-wrap: balance;
  }
}

.smart-input--list-item {
  background: white;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
}
.schedule-location-input {
  align-items: center;
  display: block;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid #e9e9e9;
  border-radius: 16px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.051);
  font-size: 16px;
  font-weight: 500;
  height: 44px;
  line-height: 24px;
  padding: 10px 35px 10px 50px;
  width: 100%;

  .schedule-location-value {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.smart-input {
  display: flex;
  flex-direction: column;
  width: 100%;

  &.active {
    .custom-input {
      border-radius: 4px 4px 0 0;
    }

    .smart-input--list {
      height: 100%;
      opacity: 1;
      margin: -1px auto 15px auto;
    }
  }

  &--list {
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 25px 0 rgba(61, 139, 232, 0.2);
    border: $blue-border-active;
    background-color: $white;
    padding-top: 15px;
    width: 85%;
    height: 0;
    opacity: 0;
    transition: height 0.4s ease-in-out;

    &-item {
      cursor: pointer;
      padding: 15px;

      &:hover {
        background-color: #fff6ec;
      }
    }
  }
}
