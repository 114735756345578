main.local-centers {
  #map {
    height: calc(100vh - 75px);
    width: 100%;
    @media screen and (max-width: 767px) {
      height: calc(100vh - 183px);
    }
  }
  @media screen and (max-width: 991px) {
    margin-top: 0;
  }
  .title-header {
    background-color: #fffaf5;
    padding: 80px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-image: url(/images/center-header-bg-1.webp),
      url(/images/center-header-bg-2.webp);
    background-position:
      left center,
      right center;
    background-repeat: no-repeat, no-repeat;
    @media screen and (max-width: 767px) {
      padding: 20px 16px;
      background: #fffaf5;
    }
    .page-title {
      font-size: 48px;
      line-height: 65px;
      text-align: center;
      font-weight: 300;
      margin: 0;
      @media screen and (max-width: 767px) {
        font-size: 32px;
        line-height: 38px;
        font-weight: 400;
      }
    }
    .page-description {
      color: #6f7283;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .map-section {
    position: relative;
  }
  .center-search-box {
    width: 594px;
    background-color: white;
    border-radius: 20px;
    position: absolute;
    left: 20px;
    padding: 24px;
    z-index: 1;
    @media screen and (max-width: 1179px) {
      width: 360px;
    }
    @media screen and (min-width: 768px) {
      height: calc(100vh - 115px) !important;
      top: 20px;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      left: 0;
      bottom: 0;
      border-radius: 20px 20px 0 0;
      //height: 110px;
      padding-bottom: 0;
      position: relative;
      margin-top: -20px;

      &#mobile-handler {
        &::before {
          cursor: grab;
          content: '';
          display: block;
          height: 4px;
          width: 46px;
          background: #e9e9e9;
          border-radius: 11px;
          position: absolute;
          left: 50%;
          top: 10px;
          transform: translateX(-50%);
        }
      }
    }
  }
  .search-input-wrap {
    position: relative;
    &::after {
      width: 24px;
      height: 24px;
      content: '';
      display: block;
      position: absolute;
      left: 14px;
      top: 10px;
      background: url('/images/search-icon-center.webp') no-repeat 0 0;
      background-size: 100%;
    }
    button.search-clear {
      border: none;
      background: none;
      position: absolute;
      top: 14px;
      right: 11px;
      &:focus {
        outline: none;
      }
    }
    > div {
      position: absolute;
      width: 100%;
      border-bottom: 1px solid #e9e9e9;
      border-right: 1px solid #e9e9e9;
      border-left: 1px solid #e9e9e9;
      margin-top: -13px;
      padding-top: 2px;
    }
    .smart-input--list-item {
      // background: white;
    }
    .schedule-location-input {
      border: 1px solid #e9e9e9;
      border-radius: 16px;
      height: 44px;
      width: 100%;
      font-size: 16px;
      padding-left: 46px;
      padding-right: 35px;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
    }
    .schedule-location-value {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .search-input {
    border: 1px solid #e9e9e9;
    border-radius: 16px;
    height: 44px;
    width: 100%;
    font-size: 16px;
    padding-left: 46px;
    padding-right: 35px;
    &::placeholder {
      color: #9598a6;
      opacity: 1;
    }

    &::-ms-input-placeholder {
      color: #9598a6;
    }
    // background: url('/images/map-search-input-icon.svg') no-repeat 10px center;
  }
  .search-listing {
    max-height: calc(100% - 64px);
    margin-top: 20px;
    overflow: auto;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }
  .search-list-item {
    padding: 20px;
    background: #f7f7f7;
    border-radius: 20px;
    font-size: 14px;
    line-height: 18px;
    flex: 48%;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1179px) {
      flex: 100%;
    }
    .title {
      font-family: var(--font-lora);
      font-size: 20px;
      font-weight: 500;
      line-height: 26px;
      margin-bottom: 16px;
      display: flex;
      align-items: flex-start;
      img.icon {
        margin-right: 8px;
      }
    }
    img.icon {
      margin-right: 8px;
    }
    .info {
      margin-bottom: 14px;
      display: flex;
      align-items: flex-start;
      &.email {
        word-break: break-word;
        margin-bottom: 34px;
      }
      a {
        color: #31364e;
        text-decoration: none;
      }
    }
    .action-btn {
      margin-top: auto;
      .submit-btn {
        background: linear-gradient(
          102.78deg,
          #ffd16f -37.89%,
          #ff865b 100.28%
        );
        border-radius: 100px;
        font-weight: 500;
        font-size: 16px;
        border: none;
        width: 100%;
        height: 40px;
        color: white;
      }
    }
  }
  h2.section-title {
    font-size: 48px;
    font-weight: 400;
    line-height: 65px;
    text-align: center;
    @media screen and (max-width: 991px) {
      font-size: 32px;
      line-height: 39px;
    }
  }

  section.nourish {
    padding: 140px 0;
    @media screen and (max-width: 991px) {
      padding: 80px 0;
    }
    .section-title {
      max-width: 598px;
      margin: 0 auto 60px;
      @media screen and (max-width: 991px) {
        margin-bottom: 32px;
      }
    }
    .nourish-features {
      display: grid;
      grid-template-areas:
        '. picture .'
        '. picture .';
      gap: 64px 80px;
      grid-template-columns: 240px auto 240px;
      @media screen and (max-width: 991px) {
        display: flex;
        flex-direction: column;
        gap: 40px;
      }
    }
    .nourish-features-item {
      text-align: center;
      .item-icon {
        display: flex;
        justify-content: center;
      }
      .item-title {
        margin: 24px 0 16px 0;
        font-family: var(--font-lora);
        font-size: 28px;
        font-weight: 400;
        line-height: 36px;
        @media screen and (max-width: 991px) {
          font-size: 24px;
          line-height: 29px;
          margin-bottom: 12px;
          margin-top: 16px;
        }
      }
      .item-description {
        font-size: 18px;
        line-height: 24px;
        color: #6f7283;
        @media screen and (max-width: 991px) {
          font-size: 16px;
          line-height: 24px;
        }
      }
      @media screen and (max-width: 991px) {
        &:nth-child(1) {
          order: 1;
        }
        &:nth-child(3) {
          order: 3;
        }
        &:nth-child(4) {
          order: 2;
        }
        &:nth-child(5) {
          order: 4;
        }
      }
    }
    .nourish-features-item.picture {
      grid-area: picture;
      img {
        width: 100%;
        border-radius: 32px;
        @media screen and (max-width: 479px) {
          height: 250px;
          object-fit: cover;
        }
      }
      @media screen and (max-width: 991px) {
        order: -1;
      }
    }
  }
  section.who-we-are {
    background-color: #f7f7f7;
    padding: 140px 0;
    @media screen and (max-width: 991px) {
      padding: 80px 0;
    }
    .section-text {
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      color: #6f7283;
      padding-top: 12px;
      max-width: 1037px;
      margin: auto;
      @media screen and (max-width: 991px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .journal-info {
      display: flex;
      padding-top: 60px;
      padding-bottom: 60px;
      @media screen and (max-width: 991px) {
        padding: 32px 0;
        flex-direction: column;
        max-width: 255px;
        margin: auto;
      }
      .feature_box {
        flex: 1;
        border-right: #e4e4e7 1px solid;
        padding: 0 24px;
        @media screen and (max-width: 991px) {
          padding: 20px 0;
          border-right: none;
          border-bottom: #e4e4e7 1px solid;
          text-align: center;
          .feature_logo {
            display: flex;
            justify-content: center;
          }
        }
        img {
          height: 47px;
          @media screen and (max-width: 991px) {
            height: 54px;
          }
        }
        &:first-child {
          padding-left: 0;
          @media screen and (max-width: 991px) {
            padding-top: 0;
          }
        }
        &:last-child {
          padding-right: 0;
          border-right: 0;
          @media screen and (max-width: 991px) {
            padding-bottom: 0;
            border-bottom: 0;
          }
        }
      }
      .feature-content {
        font-size: 20px;
        line-height: 28px;
        padding-top: 30px;
        @media screen and (max-width: 991px) {
          font-size: 18px;
          line-height: 24px;
          padding-top: 16px;
        }
      }
    }
  }
  section.our-community {
    padding: 140px 0;
    @media screen and (max-width: 991px) {
      padding: 80px 0;
    }
    .section-text {
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      color: #6f7283;
      padding-top: 12px;
      max-width: 1037px;
      margin: auto;
      @media screen and (max-width: 991px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .section-main-picture {
      padding-top: 60px;
      @media screen and (max-width: 991px) {
        padding-top: 32px;
      }
      img {
        border-radius: 32px;
        @media screen and (max-width: 479px) {
          height: 250px;
          object-fit: cover;
        }
      }
    }
  }
  section.celebrate {
    @media screen and (max-width: 575px) {
      .container {
        max-width: unset;
        padding-left: 0;
        padding-right: 0;
      }
    }
    .orange-box {
      border-radius: 32px;
      padding: 80px 120px;
      background: linear-gradient(94deg, #ffa180 3.84%, #ed7347 109.6%);
      position: relative;
      z-index: 1;
      @media screen and (max-width: 767px) {
        padding-left: 20px;
        padding-right: 20px;
      }
      @media screen and (max-width: 575px) {
        border-radius: 0;
      }
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        content: '';
        display: block;
        background-image: url(/images/hill-vector.webp);
        background-repeat: no-repeat;
        background-position: center 100%;
        background-size: 110%;
        z-index: -1;
        @media screen and (max-width: 575px) {
          background-position: 70% 100%;
          background-size: 200%;
        }
      }
    }
    .section-icon {
      display: flex;
      justify-content: center;
      margin-bottom: 40px;
    }
    .section-title {
      color: white;
      @media screen and (max-width: 991px) {
        margin-bottom: 20px;
      }
    }
    .section-text {
      color: white;
      text-align: center;
      max-width: 762px;
      margin: auto;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 32px;
      @media screen and (max-width: 991px) {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 24px;
      }
    }
    .submit-btn {
      background: white;
      color: #e47d6c;
    }
  }
  section.real {
    padding: 140px 0;
    @media screen and (max-width: 991px) {
      padding: 80px 0;
    }
    .real-testimonials {
      padding-top: 48px;
      display: flex;
      gap: 24px;
      @media screen and (max-width: 991px) {
        flex-direction: column;
        padding-top: 32px;
      }
    }
    .testimony-item {
      box-shadow: 4px 6px 25.100000381469727px 0px #6e6b6824;
      background: white;
      padding: 32px 24px;
      border-radius: 32px;
      display: flex;
      flex-direction: column;
      flex: 1;
      h3 {
        font-size: 24px;
        font-weight: 500;
        line-height: 31px;
        margin-bottom: 16px;
        @media screen and (max-width: 991px) {
          font-size: 20px;
          line-height: 26px;
        }
      }
      .testimony-comment {
        margin: 0;
        font-size: 16px;
        line-height: 24px;
      }
      .morecontent {
        span {
          display: none;
        }
      }

      .morelink {
        text-decoration: none;
        font-size: 16px;
        line-height: 24px;
        color: #3d8be8;
        cursor: pointer;
        display: table;
        margin-top: 8px;
      }
      .author {
        margin-top: auto;
        padding-top: 20px;
        display: flex;
        align-items: center;
        gap: 12px;
        &-pic {
          img {
            @media screen and (max-width: 991px) {
              width: 44px;
              height: 44px;
            }
          }
        }
        .name {
          font-size: 18px;
          line-height: 24px;
          font-weight: 500;
          @media screen and (max-width: 991px) {
            font-size: 16px;
          }
        }
        .position {
          color: #6f7283;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
  section.founder {
    background: #f7f7f7;
    padding: 140px 0;
    @media screen and (max-width: 991px) {
      padding: 80px 0;
    }
    .section-title {
      text-align: left;
      margin-bottom: 20px;
      @media screen and (max-width: 991px) {
        text-align: center;
        margin-bottom: 12px;
      }
    }
    .about-founder {
      display: flex;
      @media screen and (max-width: 991px) {
        flex-direction: column;
      }
      p {
        font-size: 18px;
        line-height: 24px;
        color: #6f7283;
        & + p {
          margin-top: 10px;
        }
        @media screen and (max-width: 991px) {
          font-size: 16px;
        }
      }
    }
    .first-col {
      width: 50%;
      padding-right: 48px;
      @media screen and (max-width: 991px) {
        width: 100%;
        padding-right: 0;
        padding-bottom: 32px;
      }
    }
    .second-col {
      width: 50%;
      img {
        border-radius: 32px;
      }
      @media screen and (max-width: 991px) {
        width: 100%;
      }
    }
  }
  section.find-art {
    padding: 140px 0;
    @media screen and (max-width: 991px) {
      padding: 80px 0;
    }
    .blue-box {
      background: url(/images/centers-find-art-bg.webp) no-repeat center;
      background-size: cover;
      border-radius: 32px;
      padding: 80px;
      @media screen and (max-width: 991px) {
        padding: 60px 16px;
      }
    }
    .section-title {
      color: white;
      text-align: center;
      max-width: 541px;
      margin: 0 auto 40px;
      @media screen and (max-width: 991px) {
        margin-bottom: 32px;
      }
    }
    .submit-btn {
      background: white;
      color: #3d8be8;
    }
  }
  .actions {
    text-align: center;
  }
  .submit-btn {
    background: linear-gradient(102.78deg, #ffd16f -37.89%, #ff865b 100.28%);
    height: 64px;
    padding: 0 32px;
    font-size: 22px;
    line-height: 24px;
    font-weight: 500;
    color: white;
    border-radius: 100px;
    border: none;
    @media screen and (max-width: 991px) {
      height: 54px;
      font-size: 18px;
    }
  }
  .container {
    @media screen and (min-width: 1400px) {
      max-width: 1340px;
    }
  }
}
#map .gm-style {
  .mapInfoTitle {
    font-family: var(--font-lora);
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
  }
  .mapInfoaddress {
    font-family: Work Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    max-width: 200px;
  }
  .mapInfoLine {
    font-family: Work Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    margin-top: 7px;
    display: flex;
    word-break: break-all;
    align-items: flex-start;
    justify-content: center;
    img {
      margin-right: 8px;
    }
  }
  button.gm-ui-hover-effect {
    top: -3px !important;
    right: -3px !important;
    &:focus {
      outline: none;
    }
  }
  div[role='dialog'] {
    &.gm-style-iw.gm-style-iw-c {
      border-radius: 16px;
      box-shadow: 0px 12px 16px -4px #00000014;
    }
  }
}
