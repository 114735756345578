$txt-color: #000;
$white: #fff;
$brand-black: #31364e;
$brand-orange: #ff9c00;

$blue: #3d8be8;
$blue-hover: #89beec;
$blue-active: #2465b3;
$piction-blue: #4191e9;
$dodger-blue: #4287f5;

$blue-border: 1px solid $blue;
$blue-border-hover: 1px solid $blue-hover;
$blue-border-active: 1px solid $blue-active;
