.get-started-floating {
  position: absolute;
  right: 0;
  width: 70px;
  top: 380px;
  .help-link {
    background: linear-gradient(102.78deg, #3d8be8 -37.89%, #89beec 100.28%);
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    padding: 15px 40px;
    transform: rotate(90deg);
    position: fixed;
    color: white;
    text-decoration: none;
    border-radius: 0 0 16px 16px;
    white-space: nowrap;
    top: calc(50% - 24px);
    right: -62px;
    cursor: pointer;
    &:hover {
      color: white;
    }
    @media screen and (max-width: 767px) {
      padding: 12px 30px;
      right: -55px;
    }
  }
  .find-course {
    display: none;
    position: fixed;
    padding: 14px 18px;
    border-radius: 14px;
    background-color: white;
    width: 324px;
    right: 70px;
    //box-shadow: 0px 8px 8px -4px #00000008;
    box-shadow: 0px 20px 24px -4px #00000014;
    top: calc(50% - 71px);
    @media screen and (max-width: 767px) {
      right: 60px;
      width: 290px;
      padding: 10px 12px;
    }
    &.show {
      display: block;
    }
    &::after {
      left: 100%;
      top: 50%;
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(255, 255, 255, 0);
      border-left-color: #fff;
      border-width: 10px;
      margin-top: -10px;
    }
    .title {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 8px;
    }
    .desc {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #6f7283;
    }
    .actions {
      display: flex;
      margin-top: 16px;
      gap: 4px;
      a {
        flex: 1;
        button {
          width: 100%;
        }
      }
      button {
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        flex: 1;
        padding: 8px 0;
        border-radius: 100px;
        height: unset;
        justify-content: center;
        @media screen and (max-width: 767px) {
          padding: 8px 0;
        }
        &.btn-primary {
          background: linear-gradient(
            102.78deg,
            #3d8be8 -37.89%,
            #89beec 100.28%
          );
          color: white;
        }
        &.btn-secondary {
          border-color: linear-gradient(
            102.78deg,
            #3d8be8 -37.89%,
            #89beec 100.28%
          );
          border-width: 1px;
          border-style: solid;
          color: #3d8be8;
          background: white;
          border-color: #3d8be8;
          &:hover,
          &:active,
          &:focus {
            border-color: #3d8be8;
            color: #3d8be8 !important;
          }
        }
        & + button {
          margin-left: 4px;
        }
      }
    }
  }
}
