.text-center {
  text-align: center;
}
.text-italic {
  font-style: italic;
}
.sr-only {
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  clip: rect(0, 0, 0, 0);
  border: 0;
  white-space: nowrap;
}
