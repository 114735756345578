.page-gurudev {
  padding: 20px 0 0;
  color: rgb(49, 54, 78);

  @media (width <= 991px) {
    padding: 0;
  }
  .page-name {
    font-weight: 600;
    padding: 36px 32px 24px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    @media (width >= 640px) {
      display: none;
    }
  }
  .font-semibold {
    font-weight: 600;
  }
  .section-first {
    @media screen and (max-width: 1023px) {
      .container {
        padding: 0;
      }
    }
    background: linear-gradient(
      rgb(255, 251, 241),
      rgb(255, 245, 220) 40%,
      rgb(255, 255, 255)
    );
    .gurudev-banner {
      background: url(../../../public/images/gurudevMainImage.webp) no-repeat 0
        0;
      background-size: 100% 100%;
      max-height: 396px;
      color: white;
      display: flex;
      align-items: center;
      padding: 0 0 0 10%;
      aspect-ratio: 1140 / 396;
      margin: 0 auto;
      @media screen and (max-width: 639px) {
        background: none;
        flex-direction: column;
        padding: 0;
        max-height: unset;
        aspect-ratio: unset;
      }
      .section-title {
        margin: 0;
        max-width: 530px;
        font-weight: 400;
        @media screen and (max-width: 1279px) and (min-width: 640px) {
          font-size: 28px;
          line-height: 28px;
          max-width: 380px;
        }
        @media screen and (max-width: 639px) {
          padding: 0 32px;
          color: black;
          max-width: 100%;
          width: 100%;
        }
        div {
          width: 477px;
          @media screen and (max-width: 639px) {
            font-weight: 600;
            font-size: 26px;
            line-height: 30px;
            width: 100%;
          }
        }
        .font-semibold {
          font-weight: 600;
          width: 100%;
          @media screen and (max-width: 639px) {
            display: none;
          }
        }
      }
      .banner-text {
        padding: 0 32px;
        color: black;
      }
    }
    @media (width >= 640px) {
      .banner-text {
        display: none;
      }
      .gurudev-image {
        display: none;
      }
    }
  }
  .gurudev-bio {
    padding: 56px 0;
    max-width: 1072px;
    margin: 0 auto;
    display: flex;
    gap: 62px;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
    @media screen and (max-width: 1023px) {
      padding: 56px 32px;
    }
    @media screen and (max-width: 639px) {
      flex-direction: column;
      padding: 0 32px;
      font-size: 16px;
      gap: 27px;
      margin-top: 83px;
    }
    .video-cta {
      width: 320px;
      position: relative;
      flex-shrink: 0;
      @media screen and (max-width: 1023px) {
        max-height: 212px;
      }
      @media screen and (max-width: 639px) {
        order: -1;
        margin: 0 auto;
        max-width: 100%;
      }
      .play-icon {
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }
  .highlights-listing {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    padding-top: 40px;
    @media screen and (max-width: 639px) {
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
    }
    .highlight-item {
      background: linear-gradient(
        rgb(255, 244, 220) 25%,
        rgb(255, 218, 150) 109%
      );
      padding: 0 49px 49px;
      margin-bottom: 50px;
      @media screen and (max-width: 639px) {
        padding: 0 26px 26px;
        max-width: 335px;
        margin-left: auto;
        margin-right: auto;
      }
      &-image {
        transform: matrix(1, 0, 0, 1, 0, -40);
        @media screen and (max-width: 639px) {
          transform: matrix(1, 0, 0, 1, 0, -24);
        }
      }
      &-title {
        font-size: 28px;
        line-height: 30px;
        margin-bottom: 10px;
        font-weight: 600;
        em {
          font-weight: 400;
        }
      }
    }
  }
  .section-social-connect {
    margin-bottom: 40px;
    @media screen and (max-width: 767px) {
      .container {
        padding: 0;
      }
    }
  }
  .social-connect-box {
    background: url(../../../public/images/Social-bannerNew.webp) no-repeat 0 0;
    background-size: cover;
    padding: 16px 48px;
    display: flex;
    @media screen and (max-width: 767px) {
      padding: 24px;
      flex-direction: column;
      background: url(../../../public//images/GsocialNew.webp) no-repeat 0 0;
      background-size: 100%;
    }
    .social-connect-text {
      color: white;
      padding: 16px;
      @media screen and (max-width: 767px) {
        padding: 0;
        max-width: 300px;
        height: 350px;
      }
    }
    .section-title {
      font-weight: 400;
      font-size: 36px;
      line-height: 54px;
      @media screen and (max-width: 767px) {
        line-height: 34px;
        margin-bottom: 20px;
        max-width: 300px;
      }
    }
    .social-links {
      background: rgba(0, 0, 0, 0.5);
      padding: 40px;
      width: 440px;
      height: 340px;
      flex-shrink: 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 60px 60px 60px;
      row-gap: 24px;
      column-gap: 40px;
      @media screen and (max-width: 767px) {
        width: 286px;
        height: unset;
        row-gap: 16px;
        column-gap: 32px;
        padding: 0;
        background: none;
        margin-left: auto;
        margin-right: auto;
      }
      a {
        display: flex;
        gap: 16px;
        align-items: center;
        color: rgb(254, 244, 220);
        font-size: 22px;
        @media screen and (max-width: 767px) {
          font-size: 16px;
        }
      }
    }
  }
}
