.page-sahaj-samadhi {
  .section-title {
    font-family: var(--font-work-sans);
    font-size: 48px;
    line-height: 45px;
    font-weight: 600;
  }
  .section-banner {
    .container {
      @media (width <= 991px) {
        padding: 0;
      }
    }
    .banner-sahaj-samadhi {
      background: rgb(255, 252, 243) url('/images/sahaj-samadhi-banner-bg.jpg')
        no-repeat bottom right;
      height: 400px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 0 30px;
      @media (width <= 768px) {
        background: rgb(255, 252, 243)
          url('/images/sahaj-samadhi-banner-mobile.jpg') no-repeat bottom center;
        background-size: cover;
        height: 655px;
        justify-content: flex-start;
        align-items: center;
      }
    }
    .banner-title {
      font-family: var(--font-work-sans);
      font-size: 48px;
      line-height: 45px;
      font-weight: 600;
      max-width: 476px;
      @media (width <= 991px) {
        font-size: 34px;
        line-height: 34px;
        text-wrap: balance;
        margin-bottom: 10px;
        max-width: 350px;
      }
      @media (width <= 768px) {
        max-width: 300px;
        padding-top: 50px;
      }
    }
    .banner-text {
      font-size: 22px;
      @media (width <= 991px) {
        max-width: 300px;
        line-height: normal;
      }
    }
    .banner-button {
      margin-top: 30px;
      @media (width <= 768px) {
        margin-top: auto;
        margin-bottom: 20px;
      }
      .button {
        background-color: rgba(116, 100, 214, 1);
        border-radius: 10px;
        color: white;
        font-size: 18px;
        line-height: 22px;
        font-weight: 700;
        width: 300px;
        height: 62px;
        padding: 0 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        @media (width <= 991px) {
          font-size: 16px;
        }
        @media screen and (max-width: 479px) {
          width: 275px;
        }
      }
    }
  }
  .sahaj-key-points {
    .key-points-listing {
      display: flex;
      padding: 50px 30px;
      justify-content: space-between;
      gap: 70px;
      @media (width <= 768px) {
        gap: 20px;
        max-width: 300px;
        margin: auto;
        padding: 50px 0;
      }
    }
    .key-points-item {
      display: flex;
      gap: 25px;
      flex: 1;
      align-items: center;
      @media (width <= 768px) {
        gap: 20px;
        flex-direction: column;
        align-items: flex-start;
      }
    }
    .key-points-item-image {
      flex-shrink: 0;
      @media (width <= 768px) {
        img {
          height: 55px;
          width: auto;
        }
      }
    }
    .key-points-item-content {
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      text-wrap: balance;
      @media (width <= 768px) {
        font-size: 16px;
        line-height: 18px;
      }
    }
  }
  .what-sahaj-samadhi {
    padding: 50px 0;
    background: rgb(255, 247, 227);
    background: linear-gradient(
      0deg,
      rgba(255, 247, 227, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    @media (width <= 768px) {
      padding-top: 0;
    }
    .box-what-sahaj {
      @media (width >= 769px) {
        display: grid;
        gap: 30px;
        grid-template-areas:
          'what-title what-video'
          'what-para1 what-video'
          'what-para2 what-video';
        align-items: center;
      }
      @media (width <= 768px) {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
      p {
        margin: 0;
      }
    }
    .section-title {
      grid-area: what-title;
      @media (width <= 768px) {
        font-size: 28px;
        line-height: 30px;
        margin-bottom: 10px;
      }
    }
    .para1 {
      grid-area: what-para1;
    }
    .para2 {
      grid-area: what-para2;
    }
    iframe.what-video {
      width: 520px;
      height: 290px;
      grid-area: what-video;
      @media (width >= 769px) {
        width: 520px;
        flex-shrink: 0;
      }
      @media (width <= 768px) {
        aspect-ratio: 16 / 9;
        max-width: 100%;
        height: auto;
      }
    }
  }
  .benefits-sahaj-samadhi {
    background: url(/images/sahaj-samadhi-benefit-moon.png) no-repeat top center;
    @media (width <= 768px) {
      background-size: 163px;
    }
    .section-title {
      text-align: center;
      padding: 80px 0;
      font-size: 36px;
      line-height: 45px;
      font-weight: 400;
      @media (width <= 768px) {
        font-size: 26px;
        line-height: 30px;
        padding: 25px 0;
      }
      strong {
        font-weight: 600;
      }
    }
    .benefit-boxes {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      @media (width <= 768px) {
        grid-template-columns: 1fr;
        gap: 10px;
      }
    }
    .benefit-box {
      position: relative;
      @media (width <= 768px) {
        max-width: 320px;
        margin: auto;
        aspect-ratio: auto;
        &:nth-child(1) {
          .box-title {
            background: url('/images/icon-black-smile.png') no-repeat bottom
              right;
          }
        }
        &:nth-child(2) {
          .box-title {
            background: url('/images/icon-black-heartbeat.png') no-repeat bottom
              right;
          }
        }
        &:nth-child(3) {
          .box-title {
            background: url('/images/icon-black-openeye.png') no-repeat bottom
              right;
          }
        }
        &:nth-child(4) {
          .box-title {
            background: url('/images/icon-black-upward.png') no-repeat bottom
              right;
          }
        }
      }
    }
    .box-content {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      text-align: center;
      padding: 30px 20px;
      @media (width <= 768px) {
        text-align: left;
        gap: 10px;
      }
    }
    .box-title {
      font-weight: 400;
      font-style: italic;
      font-size: 42px;
      line-height: 45px;
      @media (width <= 768px) {
        font-size: 28px;
        line-height: 30px;
      }
      strong {
        font-weight: 600;
        display: block;
      }
    }
    .box-text {
      @media (width <= 768px) {
        font-size: 16px;
        line-height: 18px;
      }
    }
  }
  .unlock-peace {
    margin: 50px 0;
    .section-title {
      max-width: 450px;
      margin: auto;
      text-align: center;
      font-size: 26px;
      line-height: 29px;
      font-weight: 400;
      @media (width <= 768px) {
        font-size: 24px;
        line-height: 26px;
      }
    }
    .section-action {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      @media screen and (max-width: 768px) {
        margin-top: 20px;
      }
      .button {
        background-color: rgba(116, 100, 214, 1);
        border-radius: 10px;
        color: white;
        font-size: 18px;
        line-height: 22px;
        font-weight: 700;
        width: 300px;
        height: 62px;
        padding: 0 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
      }
    }
  }
  .section-courses-nearme {
    .container {
      max-width: 1334px;
    }
    padding-bottom: 0px;
    @media screen and (max-width: 768px) {
      padding-bottom: 50px;
    }
  }
  .changing-lives {
    background: rgb(255, 247, 227);
    background: linear-gradient(0deg, #fff1d1 0%, #ffffff 100%);
    padding: 50px 0;
    .section-title {
      font-size: 36px;
      line-height: 45px;
      text-align: center;
      margin-bottom: 50px;
      @media (width <= 768px) {
        text-align: left;
        font-size: 28px;
        line-height: 30px;
        margin-bottom: 20px;
      }
    }
    .reviews {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 25px;
      @media (width <= 768px) {
        grid-template-columns: 1fr;
        gap: 15px;
      }
    }
    .review-box {
      background: #fff url(/images/review-quote.png) no-repeat 95% 15px;
      background-size: 46px;
      border-bottom: #a0b2fd 9px solid;
      padding: 20px;
      display: flex;
      flex-direction: column;
    }
    .review-title {
      font-family: var(--font-lora);
      font-style: italic;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 10px;
    }
    .review-text {
      font-family: var(--font-lora);
      font-size: 16px;
      line-height: 20px;
      font-style: italic;
    }
    .author-info {
      margin-top: auto;
      display: flex;
      align-items: center;
      gap: 20px;
      padding-top: 40px;
      @media (width <= 768px) {
        gap: 10px;
      }
    }
    .author-photo {
      @media (width <= 768px) {
        width: 62px;
        height: 62px;
      }
    }
    .author-name {
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
      font-family: var(--font-lora);
      margin-bottom: 10px;
      @media (width <= 768px) {
        font-size: 18px;
        line-height: 22px;
      }
    }
    .author-position {
      font-size: 18px;
      line-height: 24px;
      font-weight: 400;
      font-family: var(--font-lora);
      @media (width <= 768px) {
        font-size: 14px;
        line-height: normal;
      }
    }
  }
  .faqs {
    padding: 50px 0;
    .section-title {
      text-align: center;
      font-size: 42px;
      margin-bottom: 50px;
      @media (width <= 768px) {
        margin-bottom: 0px;
        text-align: left;
        font-size: 36px;
      }
    }
    .faq-item {
      &:not(:last-child) {
        border-bottom: solid 1px #dcdcdc;
      }
      .question {
        font-size: 18px;
        line-height: 22px;
        padding: 30px 11px;
        font-weight: 600;
        @media (width <= 768px) {
          padding-left: 0;
          padding-right: 0;
        }
      }
      .answer {
        padding: 0px 11px 30px;
        font-size: 16px;
        line-height: 20px;
        @media (width <= 768px) {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
  .float-bar {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    background: #fff;
    padding: 10px 0;
    .container {
      display: flex;
      gap: 20px;
      justify-content: center;
      align-items: center;
      @media (width <= 768px) {
        gap: 10px;
      }
    }
    .float-bar-title {
      font-size: 24px;
      line-height: 63px;
      font-weight: 700;
      @media (width <= 991px) {
        font-size: 16px;
        line-height: 17px;
        max-width: 150px;
      }
    }
    .float-bar-action {
      .button {
        width: 192px;
        height: 62px;
        display: flex;
        background: rgb(163, 143, 235);
        background: linear-gradient(
          90deg,
          rgba(163, 143, 235, 1) 0%,
          rgba(116, 101, 216, 1) 100%
        );
        align-items: center;
        justify-content: center;
        border-radius: 60px;
        font-weight: 600;
        font-size: 22px;
        color: white;
        text-decoration: none;
        @media (width <= 991px) {
          width: 142px;
          height: 45px;
          font-size: 16px;
        }
      }
    }
  }
}
