.page-aboutUs {
  padding: 20px 0 0;
  color: rgb(49, 54, 78);

  @media (width <= 991px) {
    padding: 0;
  }
  .page-name {
    font-weight: 600;
    @media (width >= 992px) {
      display: none;
    }
  }
  .section-title {
    font-size: 36px;
    line-height: 30px;
    font-weight: 400;
    color: #000000;
    @media (width <= 639px) {
      font-size: 24px;
      line-height: 32px;
    }
    strong {
      font-weight: 600;
    }
  }
  .section-first {
    background: linear-gradient(rgb(255, 246, 219), rgb(255, 255, 255));
    padding-top: 80px;
    @media (width <= 991px) {
      padding-top: 30px;
    }
    .section-title {
      max-width: 600px;
      margin: auto;
    }
    @media (width <= 767px) {
      text-align: left;
      .section-title {
        text-align: left !important;
        margin-top: 20px;
        margin: 0;
      }
    }
    .top-row {
      display: flex;
      margin-top: 30px;
      @media (width <= 767px) {
        flex-direction: column;
      }
    }
    .first-col {
      justify-content: space-between;
      display: flex;
      max-width: 684px;
      @media (width <= 991px) {
        width: 60%;
      }
      @media (width <= 767px) {
        width: 100%;
        align-items: flex-start;
        .gurudev-image {
          width: 150px;
          margin-left: 20px;
        }
      }
      .top-row-text1 {
        width: 40%;
        @media (width <= 767px) {
          width: auto;
        }
      }
    }
    .second-col {
      width: 40%;
      padding: 0 30px;
      align-items: flex-end;
      display: flex;
      @media (width <= 767px) {
        padding: 0;
        width: 100%;
        margin-top: 20px;
      }
    }
  }
  .section-mission {
    padding-top: 20px;
    .section-title {
      line-height: 54px;
    }
    .mission-vision-image {
      flex-shrink: 0;
      @media (width <= 991px) {
        flex-shrink: unset;
      }
    }
    .mission-vision {
      display: flex;
      align-items: center;
      @media (width <= 767px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    .mission-vision-content {
      padding: 0 30px;
      @media (width <= 991px) {
        width: 40%;
      }
      @media (width <= 767px) {
        width: 100%;
        padding: 0;
      }
    }
    .mission-vision-text {
      margin-top: 25px;
    }
  }
  .aol-courses {
    padding-top: 80px;
    @media (width <= 767px) {
      padding-top: 40px;
    }
    .section-title {
      line-height: 54px;
      padding-bottom: 20px;
    }
    .aol-courses-tiles {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      &.aol-courses-slider {
        display: unset;
      }
      &.d-md-block {
        display: flex !important;
        @media (width <= 767px) {
          display: none !important;
        }
      }
      @media (640px <= width <= 991px) {
        flex-wrap: wrap;
        gap: 20px;
      }
      .aol-courses-tile {
        position: relative;
        @media (width <= 767px) {
          padding: 0 3px;
        }
        @media (640px <= width <= 991px) {
          width: calc(50% - 10px);
          .course-tile-image {
            height: 250px;
            width: 100%;
            object-fit: cover;
            @media (768px <= width <= 991px) {
              height: 307px;
            }
          }
        }
        .course-tile-image {
          @media (width <= 767px) {
            height: 210px;
            width: auto;
            object-fit: cover;
          }
        }
      }
      .course-link {
        position: absolute;
        left: 20px;
        top: 200px;
        max-width: 180px;
        font-size: 24px;
        text-decoration: none;
        font-family: var(--font-lora);
        font-weight: 700;
        color: rgb(61, 139, 232);
        line-height: 28px;
        @media (width <= 767px) {
          font-size: 16px;
          top: 150px;
          line-height: 20px;
        }
      }
    }
    .aol-courses-content {
      padding: 40px 0;
      p + p {
        margin-top: 20px;
      }
    }
  }
  .section-service-social {
    padding: 60px 0 108px;
    @media (width <= 767px) {
      padding: 20px 0 50px;
    }
    .container {
      display: flex;
      gap: 40px;
      @media (width <= 767px) {
        flex-direction: column;
        gap: 24px;
      }
    }
    .section-content {
      display: flex;
      flex-direction: column;
      gap: 40px;
      @media (width <= 767px) {
        order: 2;
        gap: 24px;
      }
    }
    .section-image {
      flex-shrink: 0;
      @media (width <= 1279px) {
        width: 400px;
      }
      @media (width <= 1023px) {
        width: 310px;
      }
      @media (width <= 767px) {
        width: auto;
        margin: auto;
      }
    }
  }
  .section-initiatives {
    padding-top: 70px;
    padding-bottom: 70px;
    color: #000;
    .section-title {
      position: relative;
      text-align: center;
      padding: 0px 0 40px;
      @media (width <= 767px) {
        padding-bottom: 20px;
      }
      @media (width <= 639px) {
        padding-bottom: 0;
      }
      &::after {
        content: '';
        position: absolute;
        display: block;
        width: 400px;
        height: 290px;
        background: url(../../../public/images/SunImage.webp) no-repeat 0 0;
        right: -12px;
        top: -69px;
        z-index: -1;
        @media (width <= 1023px) {
          right: 0;
        }
        @media (width <= 767px) {
          width: 200px;
          height: 145px;
          background-size: contain;
          right: -15px;
          top: -30px;
        }
        @media (width <= 639px) {
          top: -50px;
        }
      }
    }
    .key-initiatives-container {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
      @media (width <= 767px) {
        justify-content: center;
      }
      .initiative-tile {
        max-width: 380px;
        padding: 32px;
        margin-top: 16px;
        .initiative-icon {
          @media (width <= 767px) {
            width: 65px;
          }
        }
      }
      .ki-head {
        display: flex;
        justify-content: space-between;
        &-left {
          display: flex;
          flex-direction: column;
          gap: 19px;
          h3 {
            font-size: 24px;
            font-weight: 400;
            line-height: 30px;
            font-style: italic;
            max-width: 160px;
            strong {
              font-style: normal;
              font-weight: 600;
            }
          }
        }
        &-right {
          flex-shrink: 0;
          @media (width <= 767px) {
            flex-shrink: unset;
          }
        }
      }
      .initiative-text {
        margin-top: 32px;
      }
    }
  }
  & + .section-footer {
    .subsection-footer-title,
    .subsection-footer-topmenu {
      display: none;
    }
  }
}
