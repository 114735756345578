@font-face {
  font-family: 'iconaol';
  src: url('/images/fonts/iconaol/iconaol.eot?zi4e70');
  src:
    url('/images/fonts/iconaol/iconaol.eot?zi4e70#iefix')
      format('embedded-opentype'),
    url('/images/fonts/iconaol/iconaol.ttf?zi4e70') format('truetype'),
    url('/images/fonts/iconaol/iconaol.woff?zi4e70') format('woff'),
    url('/images/fonts/iconaol/iconaol.svg?zi4e70#iconaol') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.icon-aol {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconaol' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.iconaol-university-1:before {
  content: '\e94d';
}
.iconaol-university:before {
  content: '\e94b';
}
.iconaol-play:before {
  content: '\e935';
}
.iconaol-lock:before {
  content: '\e932';
}
.iconaol-sms-bold:before {
  content: '\e926';
}
.iconaol-human-brain:before {
  content: '\e948';
}
.iconaol-angry:before {
  content: '\e93a';
}
.iconaol-broken-heart:before {
  content: '\e93b';
}
.iconaol-emotions:before {
  content: '\e93c';
}
.iconaol-expectations:before {
  content: '\e93d';
}
.iconaol-family:before {
  content: '\e93e';
}
.iconaol-fear:before {
  content: '\e93f';
}
.iconaol-heart:before {
  content: '\e940';
}
.iconaol-karma:before {
  content: '\e941';
}
.iconaol-love:before {
  content: '\e942';
}
.iconaol-peace:before {
  content: '\e943';
}
.iconaol-pray:before {
  content: '\e944';
}
.iconaol-relationship-2:before {
  content: '\e945';
}
.iconaol-smiley:before {
  content: '\e946';
}
.iconaol-user-2:before {
  content: '\e947';
}
.iconaol-arrow-long-right:before {
  content: '\e933';
}
.iconaol-arrow-long-left:before {
  content: '\e934';
}
.iconaol-users:before {
  content: '\e919';
}
.iconaol-yoga:before {
  content: '\e91a';
}
.iconaol-world-clock:before {
  content: '\e91b';
}
.iconaol-calendar-2:before {
  content: '\e923';
}
.iconaol-relieved:before {
  content: '\e924';
}
.iconaol-Tick:before {
  content: '\e917';
}
.iconaol-video:before {
  content: '\e936';
}
.iconaol-hindu-temple:before {
  content: '\e916';
}
.iconaol-dislike:before {
  content: '\e949';
}
.iconaol-like:before {
  content: '\e94a';
}
.iconaol-chat-flower:before {
  content: '\e930';
}
.iconaol-key:before {
  content: '\e931';
}
.iconaol-clock-bold:before {
  content: '\e925';
}
.iconaol-payment-card:before {
  content: '\e914';
}
.iconaol-pause:before {
  content: '\e94c';
}
.iconaol-eye-slash:before {
  content: '\e939';
}
.iconaol-arrow-left:before {
  content: '\e937';
}
.iconaol-arrow-right:before {
  content: '\e938';
}
.iconaol-brain:before {
  content: '\e928';
}
.iconaol-charity:before {
  content: '\e929';
}
.iconaol-focus:before {
  content: '\e92a';
}
.iconaol-sleep:before {
  content: '\e92b';
}
.iconaol-lighting:before {
  content: '\e92c';
}
.iconaol-mindfulness:before {
  content: '\e92d';
}
.iconaol-reduce-stress:before {
  content: '\e92e';
}
.iconaol-emotion:before {
  content: '\e92f';
}
.iconaol-location-bold:before {
  content: '\e927';
}
.iconaol-copy:before {
  content: '\e91c';
}
.iconaol-add-square:before {
  content: '\e91d';
}
.iconaol-trash:before {
  content: '\e91e';
}
.iconaol-call-calling:before {
  content: '\e91f';
}
.iconaol-sms:before {
  content: '\e920';
}
.iconaol-arrow-down:before {
  content: '\e921';
}
.iconaol-arrow-up:before {
  content: '\e922';
}
.iconaol-eye:before {
  content: '\e918';
}
.iconaol-logout:before {
  content: '\e915';
}
.iconaol-setting:before {
  content: '\e913';
}
.iconaol-call-bold:before {
  content: '\e911';
}
.iconaol-info-circle-bold:before {
  content: '\e912';
}
.iconaol-info-circle:before {
  content: '\e90e';
}
.iconaol-monitor-mobile:before {
  content: '\e90f';
}
.iconaol-profile-users:before {
  content: '\e910';
}
.iconaol-calendar:before {
  content: '\e90a';
}
.iconaol-location:before {
  content: '\e90b';
}
.iconaol-profile:before {
  content: '\e90c';
}
.iconaol-clock:before {
  content: '\e90d';
}
.iconaol-input-search:before {
  content: '\e900';
}
.iconaol-ascetic:before {
  content: '\e901';
}
.iconaol-death:before {
  content: '\e902';
}
.iconaol-finance:before {
  content: '\e903';
}
.iconaol-health-care:before {
  content: '\e904';
}
.iconaol-hindu-god:before {
  content: '\e905';
}
.iconaol-idea:before {
  content: '\e906';
}
.iconaol-lotus:before {
  content: '\e907';
}
.iconaol-meditation:before {
  content: '\e908';
}
.iconaol-relationship:before {
  content: '\e909';
}
